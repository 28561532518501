<template>
    <div class="card">
        <h4 class="card-header">
            Additional text
        </h4>
        <div class="card-body">
            <label>{{ activeLanguage.field }}</label>
            <input class="form-control col-md-6" v-model="additional_fields.additional_text" />
            <template v-if="!!additional_fields.additional_text">
                <div style="margin-top: 10px;">
                    <label>{{ activeLanguage.field + ' 1' }}</label>
                    <input class="form-control col-md-6" v-model="additional_fields.variable1" />
                </div>
            </template>
            <template v-if="!!additional_fields.variable1">
                <div style="margin-top: 10px;">
                    <label>{{ activeLanguage.field + ' 2' }}</label>
                    <input class="form-control col-md-6" v-model="additional_fields.variable2" />
                </div>
            </template>
            <template v-if="!!additional_fields.variable2">
                <div style="margin-top: 10px;">
                    <label>{{ activeLanguage.field + ' 3' }}</label>
                    <input class="form-control col-md-6" v-model="additional_fields.variable3" />
                </div>
            </template>
            <div class="col-md-12 btn-block">
                <button class="btn high-z hidden-xs" @click="onSubmit">Save</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        activeLanguage: {
            type: Object
        }
    },
    data() {
        return {
            additional_fields: {}
        }
    },
    methods: {
        onSubmit() {
           this.$emit('saveAdditionalText', this.additional_fields)
        }
    }
}
</script>
<style lang="scss">
    .btn-block {
        display: flex;
        justify-content: flex-end;
    }
</style>