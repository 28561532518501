<template>
    <b-modal
        @ok="reportFraud"
        @cancel="close"
        v-model="showModal"
        :title="activeLanguage.title"
        header-class="card-header pause"
    >
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="">{{activeLanguage.fraudLabel}}</label>
                    <b-form-select v-model="form.category" :options="fraudReasons" />
                </div>
            </div>
            <div class="col-12">
                <div class="form-group" style="margin-top: 15px;">
                    <label for="">{{activeLanguage.txt}}</label>
                    <textarea v-model="form.description" style="resize: none" class="form-control" :placeholder="activeLanguage.txt"></textarea>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
	export default {
		name: "FraudOrderModal",
		props: ['activeLanguage'],
        data() {
			return {
				showModal: false,
                form: {
	                category: '',
	                description: ''
                }
            }
        },
        computed: {
			fraudReasons: function() {
				if (this.activeLanguage.reason) { return [...this.activeLanguage.reason] }
            }
        },
        methods: {
	        open() {
		        this.showModal = true
                this.form.category = this.fraudReasons[0]
	        },
	        close() {
		        this.showModal = false
		        this.form = {
			        category: '',
			        description: ''
		        }
	        },
	        reportFraud() {
	        	switch (this.form.category) {
                    case 'Fraud with name':
	                    this.form.category = 'Fraude op naam'
                    	break;
			        case 'Address fraud':
				        this.form.category = 'Adres fraude'
				        break;
			        case 'Logistical fraud':
				        this.form.category = 'Logistieke fraude'
				        break;
			        case 'Fraud by acquaintance':
				        this.form.category = 'Fraude door bekende'
				        break;
			        case 'Legally incompetent':
				        this.form.category = 'Handelsonbekwaam'
				        break;
                }
		        this.$emit('reportFraud', this.form)
                this.close()
	        }
        },
	}
</script>

<style scoped>

</style>