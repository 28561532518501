<template>
  <div class="card">
    <h4 class="card-header">{{ activeLanguage.title }}</h4>
    <div class="card-body less-padding">
      <table class="table">
        <thead>
        <tr>
          <th class="text-left" width="120px">{{ activeLanguage.date }}</th>
          <th class="text-left" style="width: 142px;">{{ activeLanguage.system }}</th>
          <th class="hidden-xs" style="width: 144px;">{{ activeLanguage.paid }}</th>
          <th class="hidden-xs" style="width: 320px;">{{ activeLanguage.desc }}</th>
          <th style="width: 100px;">{{ activeLanguage.amount }}</th>
          <th v-if="alloweeToDelete"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="payment in payments">
          <td class="text-left">{{ payment.date }}</td>
          <td class="text-left">{{ payment.system }}</td>
          <td class="hidden-xs">{{ !!payment.to_client ? activeLanguage.client : activeLanguage.billink }}</td>
          <td class="hidden-xs" style="max-width: 380px;">{{ payment.description }}</td>
          <td class="amount"><span>€</span> {{ formattedAmount(payment.amount) }}</td>
          <td
              class="deleteCredit"
              @click="deletePayment(payment)"
              v-if="alloweeToDelete"
          >
            <i class="fa fa-trash" aria-hidden="true"></i>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentHistory",
  props: {
    payments: {
      type: Array,
      required: true,
      default: function () {
        return []
      }
    },
    activeLanguage: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    alloweeToDelete: {
      type: Boolean,
      required: true,
      default: function () {
        return false
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    formattedAmount() {
      return (amount) => Helper.formatAmount(amount)
    }
  },
  methods: {
    deletePayment(payment) {
      this.$emit('deletePayment', 'payment', payment)
    }
  }
}
</script>

<style scoped>

</style>