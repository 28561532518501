<template>
    <div class="card">
        <h4 class="card-header">{{ activeLanguage.title }}</h4>
        <div class="card-body less-padding">
          <div class="p-2">
            <div v-if="employeeClientData.username">
              {{ employeeClientData.username }}
            </div>
            <div v-if="employeeClientData.email">
              <a :href="`mailto:${employeeClientData.email}`">{{ employeeClientData.email }}</a>
            </div>
            <div v-if="employeeClientData.phone">
              <a :href="`tel:${employeeClientData.phone}`">{{ employeeClientData.phone }}</a>
            </div>
            <div v-if="employeeClientData.platform">
              {{ employeeClientData.platform }}
            </div>
            <div v-if="clientAgreements">
              <strong>{{ activeLanguage.agreement }}:</strong>
              <div>
                {{ clientAgreements }}
              </div>
            </div>
            <div v-if="employeeClientData && !!employeeClientData.name">
              <div>{{ employeeClientData.name }}</div>
              <div class="merchantContacts">
                <div v-b-tooltip.hover.right.title="employeeClientData.contact1" v-if="!!employeeClientData.contact1">{{employeeClientData.contact1}}</div>
                <div v-b-tooltip.hover.right.title="employeeClientData.contact2" v-if="!!employeeClientData.contact2">{{employeeClientData.contact2}}</div>
                <div v-b-tooltip.hover.right.title="employeeClientData.contact3" v-if="!!employeeClientData.contact3">{{employeeClientData.contact3}}</div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ClientDetailsEmployee",
        props: {
            employeeClientData: {
                type: Object,
                default: function() { return {} }
            },
            activeLanguage: {
		        type: Object,
		        required: true,
		        default: function() { return {} }
	        },
            clientAgreements: {
                default: function() { return {} }
            },
        },
        data() {
            return {

            }
        }
    }
</script>

<style scoped lang="scss">
    .merchantContacts {
        font-size: 13px;
        width: 140px;
        max-width: 100%;
    }
    .card {
        height: 358px !important;
    }
</style>