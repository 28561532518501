<template>
    <div>
        <div class="card">
        <h4 class="card-header">{{ activeLanguage.disputeList }}</h4>
        <div class="card-body less-padding">
            <table class="table">
                <thead>
                    <tr>
                        <th class="text-left" style="width: 96px;">{{ activeLanguage.number }}</th>
                        <th class="text-left hidden-xs">{{ activeLanguage.reason }}</th>
                        <th class="hidden-xs">{{ activeLanguage.creationDate }}</th>
                        <th class="text-left">{{ activeLanguage.status }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr 
                        v-for="(dispute, index) in disputes"
                        style="cursor: pointer;"
                        @click="goToDispute($event, dispute, index)"
                        :class="{ 'selected' : selectedIndex === index}"
                        >
                        <td>{{ dispute.disputeId }}</td>
                        <td>{{ dispute.lastDisputeReason }}</td>
                        <td>{{ onDateFormat(dispute.createdAt.date) }}</td>
                        <td>{{ formatResolved(dispute) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <DisputeEmployeeDetails
            :disputeId="disputeId"
            :id="id"
            @removeSelectedIndex="removeSelectedIndex"
            ref="disputeDetails"
        />
    </div>
</template>

<script>
import moment from "moment"
import DisputeEmployeeDetails from '@/views/dispute/employee/DisputeEmployeeDetails'

	export default {
		name: "DisputeList",
        props: {
			disputes: {
				type: Array,
                required: true,
				default: function () {
					return []
				}
            },
            activeLanguage: {
	        	type: Object,
                required: true,
                default: function () {
                    return {}
                }
            },
        },
        data() {
			return {
                selectedIndex: null,
                disputeId: null,
                id: null
            }
        },
        mounted() {
			this.openSelectedDispute()
		},
        methods: {
	        goToDispute(e, dispute, index) {
                this.selectedIndex = index
                    this.disputeId = dispute.disputeId
                    this.id = dispute.id
                    this.$refs.disputeDetails.showDisputeModal()
                    this.$refs.disputeDetails.getDisputeDetails(dispute.id, dispute.disputeId)
			},
            onDateFormat(val) {
                return moment(val).format('DD-MM-YYYY')
            },
            formatResolved(dispute) {
                return dispute.status === 'resolved' ? this.activeLanguage.closed : this.activeLanguage.active
            },
            openSelectedDispute() {
                let queryString = window.location.search
                let searchParams = new URLSearchParams(queryString)
                let openDispute = searchParams.get('openDispute')
                let id = searchParams.get('id')
                let requiredDispute = {}
                let requiredIndex = null

                if (openDispute) {
                    this.disputes.map((dispute, index) => {
                        if (dispute.id === parseInt(id)) {
                            this.disputeId = dispute.disputeId
                            this.id = dispute.id
                            requiredDispute = dispute
                            requiredIndex = index
                        }
                    })
                    this.goToDispute(null, requiredDispute, requiredIndex)
                }
            },
            removeSelectedIndex() {
                this.selectedIndex = null
            }
        },
        components: {
			DisputeEmployeeDetails
		},
	}
</script>

<style scoped>
    tbody tr:hover {
        background: #ccf0ef !important;
    }
    .selected {
        background: #ccf0ef !important;
    }
</style>