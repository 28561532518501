<template>
  <div class="card">
    <h4 class="card-header">
      {{ activeLanguage.costsAndPayments.title }}
    </h4>
    <div class="card-body less-padding">
      <div class="p-2 content">
        <div v-if="invoiceInfo.invoice_creation_date || invoiceInfo.processed_invoice_number || invoiceInfo.calculated_costs">
          <div v-if="invoiceInfo.invoice_creation_date">
            <strong>{{ activeLanguage.costsAndPayments.DateOfInvoice }}:</strong> {{ invoiceInfo.invoice_creation_date }}
          </div>

          <div v-if="invoiceInfo.processed_invoice_number">
            <strong>{{ activeLanguage.costsAndPayments.Invoice }}:</strong> {{ invoiceInfo.processed_invoice_number }}
          </div>

          <div v-if="invoiceInfo.calculated_costs">
            <strong>{{ activeLanguage.costsAndPayments.Costs }}:</strong> € {{ formattedAmount(invoiceInfo.calculated_costs) }}
          </div>
        </div>

        <div>
          <div v-if="invoiceInfo.payout_date">
            <strong>{{ activeLanguage.costsAndPayments.PaidOn }}:</strong> {{ invoiceInfo.payout_date }}
          </div>

          <div v-if="invoiceInfo.payout_invoice_number">
            <strong>{{ activeLanguage.costsAndPayments.Invoice }}:</strong> {{ invoiceInfo.payout_invoice_number }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CostsAndPayments",
  props: {
    activeLanguage: {
      type: Object,
      required: true,
      default: function() { return {} }
    },
    date: {
      type: String,
      required: true
    },
    invoiceInfo: {
      type: Object,
      required: true,
      default: function() { return {} }
    }
  },
  computed: {
    formattedAmount() {
      return (amount) => Helper.formatAmount(amount);
    },
  },
}
</script>

<style scoped lang="scss">
i {
  color: #f48d21;
}
.card{
  height: 358px !important;

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
</style>