<template>
    <div class="main">
        <div class="container">
            <div class="title">
                <div class="links float-right">
                  <template>
                    <a
                      @click.prevent="openTrackTraceModal"
                      href=""
                      class="invoice-link-simple track-link hover hidden-xs"
                      :disabled="blockBtn"
                      title="Track & Trace"
                    >
                      <i class="fas fa-truck track-icon icon" aria-hidden="true"></i>
                      <span class="text-inner">Track&nbsp;&&nbsp;Trace</span>
                    </a>
                  </template>
                        <template v-if="isAdmin">
                            <a
                            @click.prevent="openFakeInvoiceModal"
                            href=""
                            class="invoice-link-simple hover hidden-xs"
                            :disabled="blockBtn"
                            :title="activeLanguage.changeInvoice"
                        >
                          <i class="fas fa-receipt icon" aria-hidden="true"></i>
                          <span class="text-inner">{{ activeLanguage.changeInvoice }}</span>
                        </a>
                        </template>
                         <template v-if="manageOrderRight && showDisputeLink">
                            <a
                                    href="#"
                                    @click.prevent="checkDisputeGroup"
                                    class="back-link dispute-link-simple hover hidden-xs"
                                    :disabled="blockBtn"
                                    v-if="!orderDispute || orderDispute.resolved"
                                    v-show="workflow.started"
                            >
                            <i class="fa fa-flag icon" aria-hidden="true"></i>
                              <span class="text-inner">{{ activeLanguage.createDispute }}</span>
                        </a>
                        <a
                                href="#"
                                @click.prevent="showDispute(orderDispute)"
                                class="back-link dispute-link-red hover hidden-xs"
                                :disabled="blockBtn"
                                v-else
                        >
                            <i class="fa fa-flag icon" aria-hidden="true"></i>
                            <span class="text-inner">{{ activeLanguage.activeDispute }}</span>
                        </a>
                    </template>
                    <template v-if="!workflow.started">
                        <a
                                v-if="!!parseFloat(order_info.outstanding)"
                                @click.prevent="startWorkflow"
                                href=""
                                class="back-link workflow-link hover hidden-xs"
                                :disabled="blockBtn"
                        >
                            <i class="fas fa-play icon" aria-hidden="true"></i>
                            <span class="text-inner">Start Workflow</span>
                        </a>
                        <a
                                @click.prevent="openPauseModal"
                                href=""
                                class="pause-link hover hidden-xs"
                                :class="{ darkgrey: !pause }"
                                :disabled="blockBtn"
                                :title="activeLanguage.pauseOrder"
                        >
                            <i class="fas fa-pause icon" aria-hidden="true"></i>
                            <span class="text-inner">{{ activeLanguage.pauseOrder }}</span>
                        </a>
                    </template>
                    <template v-else>
                        <a
                                @click.prevent="openPauseModal"
                                href=""
                                class="pause-link hover hidden-xs"
                                :class="{ darkgrey: !pause }"
                                :disabled="blockBtn"
                                :title="pause ? activeLanguage.resumeOrder : activeLanguage.pauseOrder"
                        >
                            <i class="fas fa-pause icon" aria-hidden="true"></i>
                            <span class="text-inner">{{ activeLanguage.pauseOrder }}</span>
                        </a>
                    </template>
                    <a
                            v-if="manageOrderRight"
                            @click.prevent="handleFraud"
                            href=""
                            class="back-link fraud-link hidden-xs"
                            :class="{ darkgrey: !order_info.is_fraud }"
                            :disabled="blockBtn"
                    >
                        <i class="fa fa-exclamation-triangle icon" 
                             aria-hidden="true"></i>
                        <span class="text-inner">{{ order_info.is_fraud ? activeLanguage.fraudOrderUndo : activeLanguage.fraudOrder }}</span>
                    </a>
                    <a href="" @click.prevent="generatedLink" class="payment-link hidden-xs hover">
                        <i class="fas fa-euro-sign icon"></i> 
                        <span class="text-inner">{{ activeLanguage.payment }}</span>
                    </a>
                    <router-link
                            to="/orders"
                            class="back-link link-back-button"
                            :disabled="blockBtn"
                            :title="activeLanguage.return"
                    >
                        <i class="fas fa-arrow-left icon icon"></i>
                        <span class="text-inner">{{ activeLanguage.return }}</span>
                    </router-link>
                </div>
                <h1>
                    Order
                    <span>#{{id}}</span>
                    <!--<a><i class="fa fa-question-circle question" aria-hidden="true"></i></a>-->
                </h1>
            </div>
            <div class="row">
                <div class="col-lg-9 col-md-8 col-12">
                    <div class="row">
                        <template v-if="manageOrderRight">
                            <div class="col-4">
                                <ClientDetails
                                        :customer="customer"
                                        :activeLanguage="activeLanguage.clientDetails"
                                        :manageOrderRight="manageOrderRight"
                                        :is_guarantee="order_info.is_guarantee"
                                        @editClientDetails="openEditClientDetailsModal"
                                />
                            </div>
                            <div class="col-4">
                                <OrderInfo
                                        @getFile="getFile"
                                        :order_info="order_info"
                                        :date="orderDate"
                                        :employeeOrderData="employeeOrderData"
                                        :activeLanguage="activeLanguage.employeeOrderData"
                                        :activeLanguage2="activeLanguage.orderInfo"
                                        :invoiceDate="invoiceInfo.payout_date ? '' : invoiceDate"
                                        :trackerData="trackerData"
                                        :isAdmin="isAdmin"
                                />
                            </div>
                            <div class="col-4">
                                <ClientDetailsEmployee
                                        :employeeClientData="employeeClientData"
                                        :clientAgreements="client.agreements"
                                        :activeLanguage="activeLanguage.employeeClientData"
                                />
                            </div>
                        </template>
                        <template v-else>
                            <div :class="hasValues(invoiceInfo) ? 'col-4' : 'col-6'">
                                <ClientDetails
                                        @editClientDetails="openEditClientDetailsModal"
                                        :customer="customer"
                                        :is_guarantee="order_info.is_guarantee"
                                        :activeLanguage="activeLanguage.clientDetails"
                                />
                            </div>
                            <div :class="hasValues(invoiceInfo) ? 'col-4' : 'col-6'">
                                <OrderInfo
                                    @getFile="getFile"
                                    :order_info="order_info"
                                    :date="orderDate"
                                    :employeeOrderData="employeeOrderData"
                                    :activeLanguage="activeLanguage.employeeOrderData"
                                    :activeLanguage2="activeLanguage.orderInfo"
                                    :invoiceDate="invoiceInfo.payout_date ? '' : invoiceDate"
                                    :trackerData="trackerData"
                                    :isAdmin="isAdmin"
                                />
                            </div>
                          <div v-if="hasValues(invoiceInfo) && !manageOrderRight" class="col-4">
                            <CostsAndPayments
                              :activeLanguage="activeLanguage"
                              :invoice-info="invoiceInfo"
                              :date="orderDate"
                            />
                          </div>
                        </template>
                        <div class="col-12">
                            <OrderItems
                                    :order_info="order_info"
                                    :items="items"
                                    :date="orderDate"
                                    :penalties="penalties"
                                    :manageOrderRight="manageOrderRight"
                                    :activeLanguage="activeLanguage.orderInfoTable"
                                    :is_guarantee="order_info.is_guarantee"
                                    :orderType="customer.type"
                                    @editPenalty="openEditPenaltyModal"
                                    @editOrderInfo="openEditOrderItemsModal"
                            />
                        </div>
                        <div class="col-12">
                            <div class="links paymentcredit hidden-xs">
                                <a
                                        v-if="manageOrderRight"
                                        href="#"
                                        @click.prevent="openPenaltyModal"
                                        class="addpayment-link"
                                        :disabled="blockBtn"
                                >
                                    <img src="/img/icons/addpayment.svg">
                                    {{ activeLanguage.orderInfoTable.penalty }}
                                </a>
                                <a
                                        v-if="showPaymentCreditBtn && employeeClientData.platform !== 'pay_nl' && employeeClientData.platform !== 'buckaroo' && btoken.details.client_platform !== 'buckaroo'"
                                        v-show="showPaymentCreditBtn"
                                        v-verify-scope-view-parent="userView_parent_orders"
                                        href="#"
                                        @click.prevent="addPayment"
                                        class="addpayment-link"
                                        :disabled="blockBtn"
                                >
                                    <img src="/img/icons/addpayment.svg">
                                    {{ activeLanguage.addPaymentBtn }}
                                </a>
                                <a
                                        v-if="showPaymentCreditBtn && employeeClientData.platform !== 'pay_nl' && employeeClientData.platform !== 'buckaroo' && btoken.details.client_platform !== 'buckaroo'"
                                        v-show="showPaymentCreditBtn"
                                        v-verify-scope-view-parent="userView_parent_orders"
                                        href="#"
                                        @click.prevent="creditOrder"
                                        class="creditorder-link"
                                        :disabled="blockBtn"
                                >
                                    <img src="/img/icons/creditorder.svg">
                                    {{ activeLanguage.addCreditBtn }}
                                </a>
                            </div>

                            <AddPayment
                                    v-if="showPaymentCreditBtn && employeeClientData.platform !== 'pay_nl' && employeeClientData.platform !== 'buckaroo' && btoken.details.client_platform !== 'buckaroo'"
                                    @paymentIsAdded="getOrderDetails"
                                    :manageOrderRight="manageOrderRight"
                                    :orderData="orderData"
                                    :outstanding='order_info.outstanding'
                                    ref="OrderDetailsAddPayment"
                                    :activeLanguage="activeLanguage.addPaymentBlock"
                            />

                            <CreditOrder
                                    v-if="showPaymentCreditBtn && employeeClientData.platform !== 'pay_nl' && employeeClientData.platform !== 'buckaroo' && btoken.details.client_platform !== 'buckaroo'"
                                    @creditIsAdded="getOrderDetails"
                                    :canCreditWithReturnCosts="client.can_credit_with_return_costs"
                                    :manageOrderRight="manageOrderRight"
                                    :orderData="orderData"
                                    :items="items"
                                    :outstanding='order_info.outstanding'
                                    :payment="order_info.payment"
                                    ref="OrderDetailsCreditOrder"
                                    :activeLanguage="activeLanguage.addCreditBlock"
                            />
                        </div>
                        <div class="col-12">
                            <PaymentHistory
                                    v-if="payments.length"
                                    :payments="payments"
                                    :activeLanguage="activeLanguage.paymentTable"
                                    :alloweeToDelete="manageFinanceRight || isAdmin"
                                    @deletePayment="deleteCreditPaymentItem"
                            />
                        </div>
                        <div class="col-12">
                            <CreditHistory
                                    v-if="credits.length"
                                    :credits="credits"
                                    :activeLanguage="activeLanguage.creditTable"
                                    :alloweeToDelete="manageOrderRight && employeeClientData.platform !== 'pay_nl'"
                                    @deleteCredit="deleteCreditPaymentItem"
                            />
                        </div>
                        <div class="col-12">
                            <template v-if="disputes">
                                <DisputeList
                                    ref="DisputeList"
                                    v-if="disputes.length"
                                    :disputes="disputes"
                                    :activeLanguage="activeLanguage.disputeListTable"
                                />
                            </template>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-12">
                  <CostsAndPayments
                    v-if="hasValues(invoiceInfo) && manageOrderRight"
                    :activeLanguage="activeLanguage"
                    :invoice-info="invoiceInfo"
                    :date="orderDate"
                  />
                  <ActivityHistory
                    @activeActivity="blockBtn = true"
                    @cancelActivity="blockBtn = false"
                    @resendEmail="resendEmail"
                    :manageOrderRight="manageOrderRight"
                    :isClient="isClient"
                    @getFile="getFile"
                    :logs="logs"
                    :activeLanguage="activeLanguage.activityHistory"
                    @saveActivity="saveActivity"
                    ref="activityHistory"
                  />
                </div>
            </div>
        </div>
        <div v-if="blockBtn" class="block-overlay"></div>
        <DeleteCreditPaymentModal
                @paymentCreditIsDeleted="getOrderDetails"
                :activeLanguage="activeLanguage.deleteCreditPaymentModal"
                ref="deleteCreditPaymentModal"
        />
        <PauseWarningModal :message="activeLanguage.pauseModal.text_modal"
                ref="pauseWarningModal"
                @openPauseModal="openPauseModalRef"
         />
        <PauseOrderModal
                :activeLanguage="activeLanguage.pauseModal"
                :order_id="order_id"
                :username="btoken.details.username"
                :manageOrderRight="manageOrderRight"
                @pauseOrder="onPauseOrder"
                ref="pauseModal"
        />
        <FraudOrderModal
                :activeLanguage="activeLanguage.fraudModal"
                @reportFraud="reportFraud"
                ref="fraudModal"
        />
        <PenaltyModal
                :activeLanguage="activeLanguage.penaltyModal"
                :penalties="penalties"
                :order_id="order_id"
                ref="PenaltyModal"
                @updateOrder="getOrderDetails"
        />
        <PenaltyModalEdit
                :activeLanguage="activeLanguage.penaltyModalEdit"
                :order_id="order_id"
                ref="PenaltyModalEdit"
                @updateOrder="getOrderDetails"
        />
        <EditClientDetailsModal
                :activeLanguage="activeLanguage.editClientDetails"
                :is_guarantee="order_info.is_guarantee"
                :isAdmin="isAdmin"
                ref="EditClientDetailsModal"
                :order_info="order_info"
                @clientDetailsAreUpdated="updateOrderDetails"
                @editRestartStep="editRestartStep"
        />
        <EditOrderItemsModal
                :activeLanguage="activeLanguage.orderInfoTable"
                ref="EditOrderItemsModal"
                @orderItemsAreUpdated="updateOrderDetails"
        />
        <FileShowModal ref="fileModal" />
        <PaymentLinkModal ref="paymentLinkModal" />
        <FakeInvoiceModal
            ref="FakeInvoice"
            :activeLanguage="activeLanguage.fakeInvoiceModal"
            :clientData="employeeClientData"
            :merchantData="merchant"
            :customerData="customer"
            :orderId="order_id"
        />
        <TrackTraceModal
          ref="trackTraceModal"
          :activeLanguage="activeLanguage"
          :carriers="this.carriers"
          :createTracker="createTracker"
          :updateTracker="updateTracker"
          :trackerData="trackerData"
          :deleteFileFromTracker="deleteFileFromTracker"
        />
    </div>
</template>

<script>
    import moment from  "moment"
    import {mapGetters} from  "vuex"

    import ActivityHistory from '@/components/orderDetailsView/ActivityHistory'
    import AddPayment from '@/components/orderDetailsView/AddPayment'
    import CreditOrder from '@/components/orderDetailsView/CreditOrder'
    import ClientDetails from '@/components/orderDetailsView/ClientDetails'
    import OrderInfo from '@/components/orderDetailsView/OrderInfo'
    import OrderItems from '@/components/orderDetailsView/OrderItems'
    import CreditHistory from '@/components/orderDetailsView/CreditHistory'
    import PaymentHistory from '@/components/orderDetailsView/PaymentHistory'
    import OrderInfoEmployee from '@/components/orderDetailsView/OrderInfoEmployee'
    import ClientDetailsEmployee from '@/components/orderDetailsView/ClientDetailsEmployee'
    import AdditionalText from '@/components/orderDetailsView/AdditionalText'
    import DisputeList from '@/components/orderDetailsView/DisputeList'

    import PauseOrderModal from '@/components/orderDetailsView/modals/PauseOrderModal'
    import PauseWarningModal from '@/components/orderDetailsView/modals/PauseWarningModal'
    import FraudOrderModal from '@/components/orderDetailsView/modals/FraudOrderModal'
    import FileShowModal from '@/components/orderDetailsView/modals/FileShowModal'
    import DeleteCreditPaymentModal from '@/components/orderDetailsView/modals/DeleteCreditPaymentModal'
    import PenaltyModal from '@/components/orderDetailsView/modals/PenaltyModal'
    import PenaltyModalEdit from '@/components/orderDetailsView/modals/PenaltyModalEdit'
    import EditClientDetailsModal from '@/components/orderDetailsView/modals/ClientDetailsEditModal'
    import FakeInvoiceModal from '@/components/orderDetailsView/modals/FakeInvoiceModal'
    import EditOrderItemsModal from '@/components/orderDetailsView/modals/OrderItemsEditModal'
    import PaymentLinkModal from '@/components/orderDetailsView/modals/PaymentLinkModal'
    import TrackTraceModal from '@/components/orderDetailsView/modals/TrackTraceModal'
    import CostsAndPayments from "../../components/orderDetailsView/CostsAndPayments.vue";

    export default {
        name: "order-details",
        data() {
            return {
                id: this.$route.params.id,
                credits: [],
                customer: {},
                client: {},
                items: [],
                logs: [],
                order_info: {},
                payments: [],
                penalties: [],
                orderDate: '',
                invoiceDate: '',
                orderData: {},
                creditData: {},
                pause: {},
                order_id: null,
                orderDispute: null,
                showDisputeLink: false,
                employeeOrderData: {
                    invDoc: {}
                },
                employeeClientData: {},
                blockBtn: false,
                workflow: {},
                extra_field: '',
                merchant: {},
                showPaymentCreditBtn: false,
                disputes: null,
                carriers: [],
                trackerData: [],
                invoiceInfo: {}
            }
        },
        mounted() {
            this.getOrderDetails()
            this.hideCreditAndPaymentBtn()
        },
        methods: {
            hideCreditAndPaymentBtn() {
              if (this.isClient) {
                this.btoken.details.client_platform === 'buckaroo' ? this.showPaymentCreditBtn = false : this.showPaymentCreditBtn = true
              } else {
                this.employeeClientData.platform === 'buckaroo' ? this.showPaymentCreditBtn = false : this.showPaymentCreditBtn = true
              }
            },
            getCarriers() {
              trackerAxios.get('/api/carriers')
                .then(({data}) => this.carriers = data)
                .catch(error => console.log(error))
            },
            getTracker() {
              trackerAxios.get('/api/list', {
                params: {
                  orderId: this.order_id
                }
              })
              .then(({data}) => this.trackerData = data)
              .catch(error => console.log(error))
            },
            createTracker(carrierCode, trackAndTrace, media) {
              const params = {
                invoiceNumber: this.id,
                carrierCode,
                trackAndTrace,
                media
              }
              return trackerAxios.post('/api/private/create', params)
                .then(() => this.getTracker())
                .catch(error => {
                  throw error
                })
            },
            async updateTracker(carrierCode, trackAndTrace, media) {
              if (media.length > 0) {
                trackerAxios.post(`${process.env.VUE_APP_TRACKER_BASE_URL}/api/media`, {
                  trackAndTraceId: this.trackerData[0].id,
                  media
                })
                  .then(({data}) => this.trackerData[0].media = data.media)
                  .catch((error) => {
                    throw error
                  })
              }

              const params = {
                invoiceNumber: this.id,
                carrierCode,
                trackAndTrace
              }
              return trackerAxios.put(`/api/private/update/${this.trackerData[0].id}`, params)
                .then(() => this.getTracker())
                .catch(error => {
                  throw error
                })
            },
            deleteFileFromTracker(id) {
              trackerAxios.delete(`/api/media/${id}`)
                .then(() => this.getTracker())
                .catch(error => console.log(error))
            },
            getOrderDetails(orderId = this.id, checkIfCredited = false) {
                billinkAxios
                    .get(`app/order-details/${orderId}`)
                    .then(({ data }) => {
                      let totalPenalties = 0;

                        this.id = data.invoice_number
                        this.credits = data.credits
                        this.customer = data.customer
                        this.client = data.client
                        this.items = data.items
                        this.logs = data.logs
                        this.order_info = data.order_info
                        this.payments = data.payments
                        this.penalties = data.penalties
                        this.orderDate = data.order_info.order_date
                        this.invoiceDate = data.invoice_date
                        this.pause = data.pause.is_pause
                        this.order_id = parseFloat(data.id)
                        this.merchant = data.merchant
                        this.invoiceInfo = data.invoice_info

                        if (data.penalties.length) {
                          data.penalties.map((penalty) => {
                            totalPenalties = parseFloat(penalty.amount) + totalPenalties;
                          });
                          this.order_info.subtotal = (parseFloat(data.order_info.subtotal) - totalPenalties).toFixed(2);
                        }
                        this.orderData = {
                            order_id: parseFloat(data.id),
                            amount: parseFloat(data.order_info.subtotal),
                            credit: parseFloat(data.order_info.subtotal) - parseFloat(data.order_info.credit).toFixed(2),
                            ex_vat: parseFloat(data.order_info.subtotal_incl_vat).toFixed(2),
                            type: this.customer.type
                        }

                        if (this.customer.type === 'Company' || this.customer.type === 'Bedrijf') {
                            this.orderData.credit += (parseFloat(data.order_info.vat)).toFixed(2)
                            //this.orderData.amount = parseFloat(data.order_info.subtotal) + parseFloat(data.order_info.vat)
                        }

                        this.employeeOrderData = {
                            pauseTill: data.order_info.holded_till ? moment(data.order_info.holded_till).format('DD-MM-YYYY') : null,
                            paymentBefore: data.order_info.payment_before,
                            provider: data.order_info.check_provider,
                            invDoc: data.order_info.latest_invoice_doc
                        }
                        this.employeeClientData = data.merchant
                        this.workflow = data.workflow_info || {}
                        document.title = `${this.id.toUpperCase()} | Billink`

                        if (!parseFloat(this.orderData.amount) && checkIfCredited) {
                            this.saveActivity('Dispuut automatisch gesloten wegens credit.')
                            if (!!this.orderDispute) {
                                this.resolveDisputeGroup()
                            }
                        }

                        this.checkDisputeStatus(this.id)
                        this.getOrderClientInfo(this.id)
                        this.getCarriers()
                        this.getTracker()
                    })
                    .catch((error) => {
                        console.error(error)
                        this.$router.push(`/`)
                    })
            },
            getOrderClientInfo(inv) {
                billinkAxios
                    .get(`app/order-details/${inv}/client`)
                    .then(({data}) => {
                        this.employeeClientData = {
                            ...this.employeeClientData,
                            ...data
                        }
                      this.hideCreditAndPaymentBtn()
                    })
                    .catch(err => console.error(err))
            },
            updateOrderDetails(info, type) {
                let params = {
                    order_id: this.order_id,
                    order_info: {}
                }

                if (type === 'client') {
                    delete info.company_name

                    params.order_info = { ...info }
                } else if (type === 'items') {
                    params.order_info.items = { ...info }
                }
                
                return billinkAxios
                    .post(`app/order/edit-info`, params)
                    .then(({data}) => this.getOrderDetails())
                    .catch(err => console.error(err))
            },
            editRestartStep (info) {
                this.updateOrderDetails(info, 'client')
                    .then(data => {
                        this.orderRestart(this.order_id)
                    })
                    .catch(err => console.error(err))
            },
            orderRestart (order_id) {
                billinkAxios.post('app/order/restart', { "order_id": order_id+'' }) 
                        .then(data => {
                            console.log('Order is restarted')
                        })
            },
            deleteCreditPaymentItem(type, itemData) {
                let data = { order_id: this.order_id }

                if (type === 'credit') {
                    data.credit_id = itemData.id
                } else if (type === 'payment') {
                    data.payment_id = itemData.id
                }

                this.$refs.deleteCreditPaymentModal.open(data, type)
            },
            checkDisputeStatus(invoice) {
                disputeAxios
                    .post(`/api/employee-dispute/groups-autocomplete/confirm`, { invoice })
                    .then(({data}) => {
                        this.disputes = data
                        if (!!data.length) {
                            disputeAxios
                                .get('/api/employee-dispute/group-view/' + data[0].id)
                                .then(({ data }) => {
                                    this.orderDispute = data
                                    this.showDisputeLink = true
                                })
                                .catch(err => console.error(err))
                        } else {
                            this.showDisputeLink = true
                        }
                    })
                    .catch(err => console.error(err))
            },
            resolveDisputeGroup() {
                disputeAxios
                    .patch(`/api/employee-dispute/group-resolve/${this.orderDispute.id}`)
                    .then(({data}) => this.orderDispute.resolved = data.code === 2010)
                    .catch(err => console.error(err))
            },
            saveActivity(message) {
                let params = {
                    order: this.order_id,
                    message
                }

                billinkAxios
                    .get('app/activity/add', { params })
                    .then(({data}) => {
                        this.$refs.activityHistory.successfullyAdded(data.message)
                        this.blockBtn = false
                    })
                    .catch(err => console.error(err))
            },
            getFile(data) {
                this.$refs.fileModal.open(data)
            },
            checkDisputeGroup() {
                disputeAxios
                    .get(`/api/employee-dispute/group/check/${this.id}`)
                    .then(({ data }) => {
                        if (data.code === 2006) {
                            let confirm = window.confirm(data.message + '\nWould you like to add additional dispute?')
                            confirm ? this.$router.push(`/dispute/employee/create/${data.response.id}`) : null
                        }
                        else if (data.code === 2007) { this.createDisputeGroup() }
                    })
                    .catch(err => console.error(err))
            },
            showDispute(orderDispute) {
              if(orderDispute.dispute.length > 1) {
                this.$router.push(`/dispute/employee/show/${orderDispute.id}`)
              } else {
                let idx = this.disputes.findIndex((dispute) => parseInt(dispute.id) === parseInt(orderDispute.id))
                let dispute = this.disputes.find((dispute) => parseInt(dispute.id) === parseInt(orderDispute.id))
                this.$refs.DisputeList.goToDispute(null, dispute, idx)
              }
            },
            startWorkflow() {
                let params = {
                    order_ids: [this.orderData.order_id]
                }
                billinkAxios
                    .post('/app/workflow/start', params)
                    .then(({data}) => {
                        if (data.status === 'success') {
                            this.workflow.started = true
                        }
                    })
                    .catch(err => console.error(err))
            },
            createDisputeGroup() {
                let data = {
                    invoice: this.id,
                    locale: this.activeLanguageName,
                }

                disputeAxios
                    .post('/api/employee-dispute/group/create', data)
                    .then(({ data }) => {
                        if (data.id) {
                            this.$router.push(`/dispute/employee/create/${data.id}`)
                        } else {
                            EventBus.$emit('showAlertModal', 'Something went wrong')
                        }
                    })
                    .catch(err => console.error(err))
            },
            handleFraud() {
                if (this.order_info.is_fraud) {
                    this.undoFraud()
                } else {
                    this.openFraudModal()
                }
            },
            openFraudModal() {
                this.$refs.fraudModal.open()
            },
            reportFraud(fraudInfo) {
                let params = {
                    ...fraudInfo,
                    order_id: this.order_id
                }
                billinkAxios
                    .post('/app/fraud-order', params)
                    .then(({ data }) => this.getOrderDetails())
                    .catch(err => console.error(err))
            },
            undoFraud() {
                let params = {
                    order_id: this.order_id
                }
                billinkAxios
                    .get('/app/undo-fraud', { params })
                    .then(({ data }) => this.getOrderDetails())
                    .catch(err => console.error(err))
            },
            openPauseModal() {
                if (this.pause) {
                    billinkAxios
                        .post('app/order/resume', { order_id: this.order_id })
                        .then(({ data }) => this.pause = false)
                        .then(() => this.getOrderDetails())
                        .then(() => this.$forceUpdate())
                        .catch(err => {
                            console.error(err)
                            if (err.response.status === 400) {
                                if (err.response.data.message) {
                                EventBus.$emit('showAlertModal', err.response.data.message)
                            }
                            }
                        })
                } else {
                     if (!!this.orderDispute)
                     {
                        this.$refs.pauseWarningModal.open()
                     } else {
                         this.$refs.pauseModal.open()
                     }
                }
            },
            onPauseOrder() {
                this.pause = true

                setTimeout(() => {
                    this.getOrderDetails()
                    this.$forceUpdate()
                }, 0)
            },
            addPayment() {
                this.$refs.OrderDetailsCreditOrder.close()
                this.$refs.OrderDetailsAddPayment.open()
            },
            creditOrder() {
                this.$refs.OrderDetailsAddPayment.close()
                this.$refs.OrderDetailsCreditOrder.open()
            },
            resendEmail(email) {
                billinkAxios
                    .post('app/resend-email', { email })
                    .then(({ data }) => EventBus.$emit('showAlertModal', data.message))
                    .catch(err => console.error(err))
            },
            openPenaltyModal() {
                this.$refs.PenaltyModal.open()
            },
            openEditPenaltyModal(penalty) {
                this.$refs.PenaltyModalEdit.open(penalty)
            },
            openEditClientDetailsModal() {
                this.$refs.EditClientDetailsModal.open({ ...this.employeeClientData, ...this.customer })
            },
            openEditOrderItemsModal() {
                this.$refs.EditOrderItemsModal.open(this.items)
            },
            saveAdditionalText(data) {
                let params = {
                    order_id: this.order_id,
                    order_info: {
                        ...data
                    }
                }
                billinkAxios
                    .post(`app/order/edit-info`, params)
                    .then(({data}) => this.getOrderDetails())
                    .catch(err => console.error(err))
            },
            generatedLink() {
                let body = {
                    link: `${process.env.VUE_APP_BILLINK_BETALEN_URL}${this.id}`
                }
                billinkAxios
                    .post('/app/generate-short-link', body)
                    .then(({ data }) => {
                        //window.location.replace(data.short_link)
                        this.$refs.paymentLinkModal.open(data.short_link)
                    })
                    .catch(err => console.error(err))
            },
            openPauseModalRef() {
                this.$refs.pauseModal.open()
            },
          openFakeInvoiceModal() {
              this.$refs.FakeInvoice.open()
          },
          openTrackTraceModal() {
            this.$refs.trackTraceModal.open()
          },
          hasValues(obj) {
              return Helper.anyValueFilled(obj)
          }
        },
        beforeRouteUpdate(to, from, next) {
            next()
            if (to.name === 'orders.item') {
                this.getOrderDetails(to.params.id)
            }
        },
        components: {
          CostsAndPayments,
            ActivityHistory,
            AddPayment,
            CreditOrder,
            ClientDetails,
            OrderInfo,
            OrderItems,
            PaymentHistory,
            CreditHistory,
            PauseOrderModal,
            FileShowModal,
            OrderInfoEmployee,
            ClientDetailsEmployee,
            FraudOrderModal,
            DeleteCreditPaymentModal,
            PenaltyModal,
            PenaltyModalEdit,
            EditClientDetailsModal,
            EditOrderItemsModal,
            AdditionalText,
            PaymentLinkModal,
            PauseWarningModal,
            FakeInvoiceModal,
            DisputeList,
            TrackTraceModal
        },
        computed: {
            ...mapGetters({
                activeLanguage: 'getOrderDetailsLanguage',
                btoken: 'getBillinkToken',
                manageOrderRight: 'userManage_orders',
                manageFinanceRight: 'userManage_finance',
                isAdmin: 'isAdmin',
                activeLanguageName: 'getActiveLanguageName',
                userView_parent_orders: 'userView_parent_orders',
                isClient: 'isClient',
                userDetails: 'getBillinkUserDetails'
            }),
        },
    }
</script>

<style scoped lang="scss">
    .links {
        display: flex;
    }
    .workflow-link {
        display: inline-block;
        width: 40px;
        height: 39px;
        background: #4db5af;
        border-radius: 50px;
        transition: width .3s ease-in-out;
        overflow: hidden;
        position: relative;
        white-space: nowrap;
        .icon {
            font-size: 16px;
            top: 8px;
            left: 13px;
            position: relative;
            margin: 0;
            color: white;
        } 
        .text-inner {
            margin-left: 25px;
            font-size: 17px;
            color: white;
            opacity: 0;
            position: absolute;
            margin-top: 5px;
            z-index: -1;
            transition: opacity .4s ease;
        }
    }
    .workflow-link:hover {
        width: 198px;  
        .text-inner {
            opacity: 1;
            z-index: 1;
        }
    }

    .dispute-link-simple {
        display: inline-block;
        width: 40px;
        height: 39px;
        background: #f48d21;
        border-radius: 50px;
        transition: width .3s ease-in-out; 
        position: relative;
        top: -1px;
        overflow: hidden;
        .icon {
            font-size: 16px;
            top: 8px;
            left: 12px;
            position: relative;
            margin: 0;
            color: white;
        } 
        .text-inner {
            margin-left: 25px;
            font-size: 17px;
            color: white;
            opacity: 0;
            position: absolute;
            margin-top: 5px;
            z-index: -1;
            transition: opacity .4s ease;
        }
    }

    .dispute-link-simple:hover {
        width: 198px;  
        .text-inner {
            opacity: 1;
            z-index: 1;
        }
    }

    .dispute-link-red {
        display: inline-block;
        width: 40px;
        height: 39px;
        background: red;
        border-radius: 50px;
        transition: width .3s ease-in-out; 
        position: relative;
        top: -1px;
        overflow: hidden;
        .icon {
            font-size: 16px;
            top: 8px;
            left: 12px;
            position: relative;
            margin: 0;
            color: white;
        } 
        .text-inner {
            margin-left: 25px;
            font-size: 17px;
            color: white;
            opacity: 0;
            position: absolute;
            margin-top: 5px;
            z-index: -1;
            transition: opacity .4s ease;
        }
    }
    .dispute-link-red:hover {
        width: 198px;  
        .text-inner {
            opacity: 1;
            z-index: 1;
        }
    }

    .invoice-link-simple {
      display: inline-block;
      width: 40px;
      height: 39px;
      background: #f48d21;
      border-radius: 50px;
      transition: width .3s ease-in-out;
      position: relative;
      top: -1px;
      overflow: hidden;
      .icon {
        font-size: 16px;
        top: 7px;
        left: 13px;
        position: relative;
        margin: 0;
        color: white;
      }
      .text-inner {
        margin-left: 20px;
        font-size: 17px;
        color: white;
        opacity: 0;
        position: absolute;
        margin-top: 7px;
        z-index: -1;
        transition: opacity .4s ease;
      }

      .track-icon {
        top: 8px;
        left: 11px;
      }

      &.track-link:hover {
        width: 170px;
      }
    }

    .invoice-link-simple:hover {
      width: 210px;
      .text-inner {
        opacity: 1;
        z-index: 1;
      }
    }

    .pause-link {
        display: inline-block;
        width: 40px;
        height: 39px;
        background: #f48d21;
        border-radius: 50px;
        transition: width .3s ease-in-out;
        overflow: hidden;
        position: relative;
        .icon {
            font-size: 16px;
            top: 8px;
            left: 13px;
            position: relative;
            margin: 0;
            color: white;
        } 
        .text-inner {
            margin-left: 25px;
            font-size: 17px;
            color: white;
            opacity: 0;
            position: absolute;
            margin-top: 6px;
            z-index: -1;
            transition: opacity .4s ease;
        }
    }
    .pause-link:hover {
        width: 138px;  
        .text-inner {
            opacity: 1;
            z-index: 1;
        }
    }
    .payment-link {
        display: inline-block;
        width: 40px;
        height: 39px;
        background: #f48d21;
        border-radius: 50px;
        position: relative;
        top: 0px;
        transition: width .3s ease-in-out;
        overflow: hidden;
        .icon {
            font-size: 16px;
            top: 8px;
            left: 14px;
            position: relative;
            margin: 0;
            color: white;
        } 
        .text-inner {
            margin-left: 25px;
            font-size: 17px;
            color: white;
            opacity: 0;
            position: absolute;
            margin-top: 6px;
            z-index: -1;
            transition: opacity .4s ease;
        }
    }
    .payment-link:hover {
        width: 178px;  
        .text-inner {
            opacity: 1;
            z-index: 1;
        }
    }
    .fraud-link {
        display: inline-block;
        width: 40px;
        height: 39px;
        background: darkred;
        border-radius: 50px;
        transition: width .3s ease-in-out;
        overflow: hidden;
        .icon {
            font-size: 15px;
            top: 6px;
            left: 12px;
            position: relative;
            margin: 0;
            color: white;
        } 
        .text-inner {
            margin-left: 25px;
            font-size: 17px;
            color: white;
            opacity: 0;
            position: absolute;
            margin-top: 5px;
            z-index: -1;
            transition: opacity .4s ease;
        }
    }
    .fraud-link:hover {
        width: 178px;  
        .text-inner {
            opacity: 1;
            z-index: 1;
        }
    }
    .link-back-button {
        display: inline-block;
        width: 40px;
        height: 39px;
        background: #f48d21;
        border-radius: 50px;
        transition: width .3s ease-in-out;
        overflow: hidden;
        .icon {
            font-size: 16px;
            top: 8px;
            left: 13px;
            position: relative;
            margin: 0;
            color: white;
        } 
        .text-inner {
            margin-left: 25px;
            font-size: 17px;
            color: white;
            opacity: 0;
            position: absolute;
            margin-top: 6px;
            z-index: -1;
            transition: opacity .4s ease;
        }
    }
    .link-back-button:hover {
        width: 117px;  
        .text-inner {
            opacity: 1;
            z-index: 1;
        }
    }
    .dispute-link:hover .fa.fa-flag{
        color: #f48d21 !important;
    }
    .overflow {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .block-overlay {
        pointer-events: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background:#fff;
        opacity:0.5;
    }
    .darkgrey {
      background: darkgrey;
      }
    @media (max-width: 1400px) {
        .table {
            td, th {
                font-size: 12px;
                padding: 5px;
            }
        }
        .table-des{
            width: 290px;
        }
        .overflow{
            width: 290px;
        }
    }
    @media (max-width: 992px) {
        .table-des{
            width: 195px;
        }
        .overflow {
            width: 195px;
        }
    }
    @media (max-width: 480px) {
        .table {
            td, th {
                font-size: 9px;
                padding: 2px;
            }
        }
        .table-des{
            width: 100px;
        }
        .overflow{
            width: 100px;
        }
    }
    @media (max-width: 768px) {
        h1 {
            text-align: center;
        }
        .links.float-right{
            float: none !important;
            > a{
                display: block;
                margin-bottom: 10px;
                text-align: right;
            }
        }
    }
</style>
