<template>
    <b-modal
        v-model="showModal"
        :title="activeLanguage.title"
        header-class="card-header pause"
        :hide-footer="true"
        class="pause-txt"
        size="lg"
    >
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <label for="ordertype" class="col-sm-4 col-form-label">{{ activeLanguage.orderType }}</label>
                    <div class="col-sm-8">
                        <template v-if="isAdmin">
                            <b-form-select
                                :disabled="isAdmin"
                                v-model="form.order_type"
                                @input="changeOrderType"
                                id="ordertype"
                            >
                                <option value="Private">{{ activeLanguage.consumer }}</option>
                                <option value="Company">{{ activeLanguage.business }}</option>
                            </b-form-select>
                        </template>
                        <template v-else>
                            <b-form-select
                                :disabled="disabled"
                                v-model="form.order_type"
                                @input="changeOrderType"
                                id="ordertype"
                            >
                                <option value="Private">{{ activeLanguage.consumer }}</option>
                                <option value="Company">{{ activeLanguage.business }}</option>
                            </b-form-select>
                        </template>
                    </div>
                </div>
                <div class="form-group row" v-if="showBirthday">
                    <label for="orderdate" class="col-sm-4 col-form-label">
                        {{ activeLanguage.birth }}
                    </label>
                    <div class="col-sm-8">
                        <template v-if="isAdmin">
                            <Datepicker
                                v-model="form.customer_birthdate"
                                :name="activeLanguage.orderDate"
                                :typeable="true"
                                format="dd-MM-yyyy"
                                initial-view="year"
                                :calendar-button="true"
                                calendar-button-icon="fa fa-calendar"
                                input-class="form-control actual_range"
                            />
                        </template>
                        <template v-else>
                            <Datepicker
                                v-model="form.customer_birthdate"
                                :name="activeLanguage.orderDate"
                                :typeable="true"
                                format="dd-MM-yyyy"
                                :disabled="disabled"
                                initial-view="year"
                                :calendar-button="true"
                                calendar-button-icon="fa fa-calendar"
                                input-class="form-control actual_range"
                            />
                        </template>
                    </div>
                </div>
                <div class="form-group row" v-else>
                    <label for="phone" class="col-sm-4 col-form-label"> {{ activeLanguage.company }}</label>
                    <div class="col-sm-8">
                        <template v-if="isAdmin">
                            <input
                                v-model="form.company_name"
                                :name="activeLanguage.company"
                                type="text"
                                :disabled="isAdmin"
                                class="form-control"
                            >
                        </template>
                        <template v-else>
                            <input
                                v-model="form.company_name"
                                :name="activeLanguage.company"
                                :disabled="disabled"
                                type="text"
                                class="form-control"
                            >
                        </template>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="phone" class="col-sm-4 col-form-label"> {{ activeLanguage.phone }}</label>
                    <div class="col-sm-8">
                        <template v-if="!isAdmin">
                            <input
                                v-model="form.customer_phone_number"
                                :name="activeLanguage.phone"
                                type="text"
                                :disabled="disabled"
                                class="form-control"
                                id="phone"
                            >
                        </template>
                        <template v-else>
                            <input
                                v-model="form.customer_phone_number"
                                :name="activeLanguage.phone"
                                type="text"
                                class="form-control"
                                id="phone"
                            >
                        </template>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="zipcode3" class="col-sm-4 col-form-label"> {{ activeLanguage.zip }}</label>
                    <div class="col-sm-8">
                        <template v-if="isAdmin">
                            <input
                                v-model="form.customer_postcode"
                                :name="activeLanguage.zip"
                                :disabled="isAdmin"
                                type="text"
                                class="form-control"
                                id="zipcode3"
                            >
                        </template>
                        <template v-else>
                            <input
                                v-model="form.customer_postcode"
                                :name="activeLanguage.zip"
                                :disabled="disabled"
                                type="text"
                                class="form-control"
                                id="zipcode3"
                            >
                        </template>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="streetname" class="col-sm-4 col-form-label"> {{ activeLanguage.street }}</label>
                    <div class="col-sm-8">
                        <template v-if="isAdmin">
                            <input
                                v-model="form.customer_street"
                                :name="activeLanguage.street"
                                :disabled="isAdmin"
                                type="text"
                                class="form-control"
                                id="streetname"
                            >
                        </template>
                        <template v-else>
                            <input
                                v-model="form.customer_street"
                                :name="activeLanguage.street"
                                :disabled="disabled"
                                type="text"
                                class="form-control"
                                id="streetname"
                            >
                        </template>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="city" class="col-sm-4 col-form-label"> {{ activeLanguage.city }}</label>
                    <div class="col-sm-8">
                        <template v-if="isAdmin">
                            <input
                                v-model="form.customer_city"
                                :name="activeLanguage.city"
                                :disabled="isAdmin"
                                type="text"
                                class="form-control"
                                id="city"
                            />
                        </template>
                        <template v-else>
                            <input
                                v-model="form.customer_city"
                                :name="activeLanguage.city"
                                :disabled="disabled"
                                type="text"
                                class="form-control"
                                id="city"
                            />
                        </template>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="country" class="col-sm-4 col-form-label"> {{ activeLanguage.country }}</label>
                    <div class="col-sm-8">
                        <template v-if="isAdmin">
                            <b-form-select
                                v-model="form.customer_country_code"
                                :disabled="isAdmin"
                                :options="countries"
                                id="country"
                                style="font-size: 14px;"
                            />
                        </template>
                        <template v-else>
                            <b-form-select
                                v-model="form.customer_country_code"
                                :disabled="disabled"
                                :options="countries"
                                id="country"
                                style="font-size: 14px;"
                            />
                        </template>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="e-mail" class="col-sm-4 col-form-label"> {{ activeLanguage.email }}</label>
                    <div class="col-sm-8">
                        <template v-if="isAdmin">
                            <input
                                v-model="form.customer_email"
                                :name="activeLanguage.email"
                                type="text"
                                class="form-control"
                                id="e-mail"
                            >
                        </template>
                        <template v-else>
                            <input
                                v-model="form.customer_email"
                                :name="activeLanguage.email"
                                type="text"
                                :disabled="disabledEmailAddition"
                                class="form-control"
                                id="e-mail"
                            >
                        </template>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="sex" class="col-sm-4 col-form-label"> {{ activeLanguage.sex }}</label>
                    <div class="col-sm-8">
                        <template v-if="isAdmin">
                            <b-form-select
                                v-model="form.customer_sex"
                                :options="genderArr"
                                id="sex"
                            />
                        </template>
                        <template v-else>
                            <b-form-select
                                v-model="form.customer_sex"
                                :options="genderArr"
                                :disabled="disabled"
                                id="sex"
                            />
                        </template>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group row">
                    <label for="firstname" class="col-sm-4 col-form-label">{{ activeLanguage.firstName }}</label>
                    <div class="col-sm-8">
                        <template v-if="isAdmin">
                            <input
                                v-model="form.customer_first_name"
                                :disabled="isAdmin"
                                :name="activeLanguage.firstName"
                                type="text"
                                class="form-control"
                                id="firstname"
                            >
                        </template>
                        <template v-else>
                            <input
                                v-model="form.customer_first_name"
                                :disabled="disabled"
                                :name="activeLanguage.firstName"
                                type="text"
                                class="form-control"
                                id="firstname"
                            >
                        </template>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="lastname" class="col-sm-4 col-form-label">{{ activeLanguage.lastName }}</label>
                    <div class="col-sm-8">
                        <template v-if="isAdmin">
                            <input
                                v-model="form.customer_last_name"
                                :disabled="isAdmin"
                                :name="activeLanguage.lastName"
                                type="text"
                                class="form-control"
                                id="lastname"
                            >
                        </template>
                        <template v-else>
                            <input
                                v-model="form.customer_last_name"
                                :disabled="disabled"
                                :name="activeLanguage.lastName"
                                type="text"
                                class="form-control"
                                id="lastname"
                            >
                        </template>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="housenumber" class="col-sm-4 col-form-label">{{ activeLanguage.houseNum }}</label>
                    <div class="col-sm-8">
                        <template v-if="isAdmin">
                            <input
                                v-model="form.customer_house_number"
                                :disabled="isAdmin"
                                :name="activeLanguage.houseNum"
                                type="text"
                                class="form-control"
                                id="housenumber"
                            >
                        </template>
                        <template v-else>
                            <input
                                v-model="form.customer_house_number"
                                :disabled="disabled"
                                :name="activeLanguage.houseNum"
                                type="text"
                                class="form-control"
                                id="housenumber"
                            >
                        </template>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="addition" class="col-sm-4 col-form-label">{{ activeLanguage.addition }}</label>
                    <div class="col-sm-8">
                            <template v-if="isAdmin">
                                <input
                                    v-model="form.customer_house_extension"
                                    :disabled="isAdmin"
                                    type="text"
                                    class="form-control"
                                    id="addition"
                                >
                            </template>
                            <template v-else>
                                <input
                                    v-model="form.customer_house_extension"
                                    :disabled="disabledEmailAddition"
                                    type="text"
                                    class="form-control"
                                    id="addition"
                                >
                            </template>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="vatnumber" class="col-sm-4 col-form-label">{{ activeLanguage.vatNum }}</label>
                    <div class="col-sm-8">
                        <template>
                            <input
                                v-model="form.vat_number"
                                :name="activeLanguage.vatNum"
                                type="text"
                                class="form-control"
                                id="vatnumber"
                            >
                        </template>
                        
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">{{ activeLanguage.field }}</label>
                    <div class="col-sm-8">
                        <input class="form-control" v-model="order_info.additional_text" />
                    </div>
                </div>
                <div class="form-group row">
                        <label class="col-sm-4 col-form-label">{{ activeLanguage.field + ' 2' }}</label>
                        <div class="col-sm-8">
                            <input class="form-control" v-model="order_info.variable1" />
                        </div>
                </div>
                <div class="form-group row">
                        <label class="col-sm-4 col-form-label">{{ activeLanguage.field + ' 3' }}</label>
                        <div class="col-sm-8">
                            <input class="form-control" v-model="order_info.variable2" />
                        </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">{{ activeLanguage.field + ' 4' }}</label>
                        <div class="col-sm-8">
                            <input class="form-control" v-model="order_info.variable3" />
                        </div>
                </div>
            </div>
            <div class="col-md-12 footer-btns">
                <button class="btn btn-secondary" @click="close">Cancel</button>
                <button class="btn btn-secondary" @click="editOrder">{{ activeLanguage.btnEdit }}</button>
                <button class="btn btn-primary" @click="editRestartStep">{{ activeLanguage.btnEditRestartStep }}</button>
            </div>
        </div>
    </b-modal>
</template>

<script>
    import Datepicker from '../../common/reusable/datepicker/components/Datepicker'
    import AdditionalText from '../AdditionalText.vue'

	import countries from 'i18n-iso-countries'

	export default {
		name: "ClientDetailsEditModal",
        props: {
	        activeLanguage: {
	        	type: Object,
                default: function () {
                    return {}
                }
            },
	        isAdmin: {
	        	type: Boolean,
                default: false
            },
            is_guarantee: {
                type: Boolean
            },
            order_info: {
                type: Object
            }
        },
        data() {
			return {
				showModal: false,
				showDifferentDeliveryAddress: false,
				form: {},
                countries: [],
                showBirthday: false,
                genderArr: [
                    {
	                    text: 'mr',
	                    value: 'M'
                    },
	                {
		                text: 'mrs',
		                value: 'F'
	                },
	                {
		                text: 'unknown',
		                value: 'U'
	                }
                ]
            }
        },
        computed: {
            disabled () {
                if (!!this.is_guarantee) {
                    return true 
                } else {
                    return false
                }
            },
            disabledEmailAddition () {
                if (!!this.is_guarantee) {
                    return false;
                } else if (!this.is_guarantee) {
                     return false   
                } else {
                    return true;
                }
            }
        },
        mounted() {
            this.setCountrySelection()
        },
		watch: {
			activeLanguage: function() { this.setCountrySelection() }
		},
        methods: {
			changeOrderType() {
				this.showBirthday = !(this.form.order_type === 'Company' || this.form.order_type === 'Bedrijf')
            },
	        close() {
		        this.showModal = false
		        this.showBirthday = false
	        },
			open(data) {
				this.form.company_name = data.customer_info
				this.form.customer_email = data.customer_email
				this.form.customer_city = data.customer_city
				this.form.customer_country_code = this.findCountry(data.country_code)
				this.form.customer_first_name = data.customer_first_name
				this.form.customer_last_name = data.customer_last_name
				this.form.customer_phone_number = data.customer_phone
				this.form.customer_postcode = data.customer_postal_code
				this.form.customer_house_number = data.house_number
				this.form.customer_street = data.street
				this.form.customer_house_extension = data.extension
				this.form.customer_birthdate = data.birthday
				this.form.customer_sex = data.sex
				this.form.order_type = data.type === 'Bedrijf' ? 'Company' : 'Private'
                this.form.vat_number = data.vat_number

                this.changeOrderType()

                setTimeout(() => this.showModal = true, 0)

            },
            findCountry(tag) {
				let country = this.countries.find((item) => item.value === tag) || {}
				return country.value
            },
	        setCountrySelection() {
		        countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
		        countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));

		        let locale = localStorage.getItem('localization'),
			        localeType = locale === 'english' ? 'en' : 'nl',
			        countryListArr = [],
			        countryList = countries.getNames(localeType),
			        country = {
				        value: "NL",
				        text: countries.getName("NL", localeType),
			        }

		        Object.keys(countries.getNames(localeType))
			        .forEach((item) => {
				        if (item !== country.value) {
					        countryListArr.push({
						        value: item,
						        text: countryList[item]
					        })
				        }
			        })

		        countryListArr.unshift(country)
		        this.countries = countryListArr
	        },
	        editOrder() {
                this.form.additional_text = this.order_info.additional_text
                this.form.variable1 = this.order_info.variable1
                this.form.variable2 = this.order_info.variable2
                this.form.variable3 = this.order_info.variable3
				// this.form.order_type = (this.form.orderType === 'Private' || this.form.orderType === 'Consument') ? 'Private' : 'Company'
		        this.$emit('clientDetailsAreUpdated', this.form, 'client')
		        setTimeout(() => this.close(), 0)
            },
            editRestartStep () {
                this.form.additional_text = this.order_info.additional_text
                this.form.variable1 = this.order_info.variable1
                this.form.variable2 = this.order_info.variable2
                this.form.variable3 = this.order_info.variable3
                this.$emit('editRestartStep', this.form)
                setTimeout(() => this.close(), 0)
            }

        },
        components: {
            Datepicker,
            AdditionalText
        }
	}
</script>

<style lang="scss" scoped>
    .footer-btns {
        display: flex;
        justify-content: flex-end;
        margin-top: 36px;
        button {
            margin-right: 11px;
        }
        .btn-primary {
            border: none !important;
        }
    }
</style>