<template>
  <form @submit.prevent="addPayment" class="collapse show" id="addpayment" v-if="show">
    <div class="card">
      <h4 class="card-header">{{ activeLanguage.title }}</h4>
      <div class="card-body pt-0 p-0">
        <template v-if="manageOrderRight">
          <h3>{{ activeLanguage.step0 }}</h3>
          <div class="padding pb-3">
            <div class="form-check" style="padding-left: 0">
              <label class="custom-control custom-checkbox form-check-label" for="idb">
                <input
                    type="radio"
                    name="paymentType"
                    id="idb"
                    value="billink"
                    @change="selectPayment(0)"
                    class="custom-control-input form-check-input mr-2"
                />
                <span class="custom-control-indicator"></span>
                {{ activeLanguage.billink }}
              </label>
            </div>
            <div class="form-check" style="padding-left: 0;" v-if="!manageOrderRight">
              <label class="custom-control custom-checkbox form-check-label" for="idw">
                <input
                    type="radio"
                    name="paymentType"
                    id="idw"
                    value="webshop"
                    @change="selectPayment(1)"
                    class="custom-control-input form-check-input mr-2"
                />
                <span class="custom-control-indicator"></span>
                {{ activeLanguage.webshop }}
              </label>
            </div>
          </div>
        </template>
        <template v-if="showStep1">
          <h3>{{ activeLanguage.step1 }}</h3>
          <div class="padding pb-3">
            <div class="form-check" style="padding-left: 0;" id="paymentCheckType">
              <label class="custom-control custom-checkbox form-check-label">
                <input
                    type="radio"
                    name="radio"
                    id="id1"
                    value="1"
                    @change="changePaymentType('full')"
                    class="custom-control-input form-check-input mr-2"
                />
                <span class="custom-control-indicator"></span>
                {{ activeLanguage.full }}
              </label>
            </div>
            <div class="form-check" style="padding-left: 0;">
              <label class="custom-control custom-checkbox form-check-label">
                <input
                    type="radio"
                    name="radio"
                    id="id2"
                    value="1"
                    @change="changePaymentType('float')"
                    class="custom-control-input form-check-input mr-2"
                />
                <span class="custom-control-indicator"></span>
                {{ activeLanguage.part }}
              </label>
            </div>
          </div>
        </template>

        <div class="collapse show" id="fullpayment" v-if="showAdd">

          <h3>{{ activeLanguage.step2 }}</h3>

          <div class="padding">
            <div class="form-row">
              <div class="form-group col-md-8">
                <label for="note">{{ activeLanguage.note }}</label>
                <textarea v-model="description" class="form-control" id="note" rows="2" style="resize: none"></textarea>
              </div>
              <div class="form-group col-md-4">
                <label for="amount">{{ activeLanguage.amount }}</label>
                <InputPrice
                  v-model="amount"
                  :placeholder="activeLanguage.enterAmount"
                  :disabled="!!fixed"
                />
              </div>
            </div>
          </div>

        </div>

        <div class="padding text-right my-4">
          <a class="btn btn-secondary" href="#" @click.prevent="close">{{ activeLanguage.closeBtn }}</a>
          &nbsp;
          <button type="submit" class="btn">{{ activeLanguage.saveBtn }}</button>
        </div>

      </div>
    </div>

  </form>
</template>

<script>
import InputPrice from "../common/reusable/InputPrice.vue";

export default {
  name: "add-payment",
  props: {
    activeLanguage: {
      type: Object,
      required: true
    },
    orderData: {
      type: Object,
      required: true
    },
    manageOrderRight: {
      type: Boolean,
      default: false
    },
    outstanding: {
      type: String
    }
  },
  data() {
    return {
      show: false,
      showAdd: false,
      description: '',
      fixed: null,
      amount: 0,
      paymentType: 1,
      showStep1: !this.manageOrderRight,
    }
  },
  methods: {
    selectPayment(type) {
      setTimeout(() => {
        this.paymentType = type
        this.showStep1 = true
        setTimeout(() => Helper.scrollTo('paymentCheckType', 'start'), 0)
      }, 200)
    },
    open() {
      this.show = true
      setTimeout(() => Helper.scrollTo('addpayment', 'start'), 0)
    },
    close() {
      this.show = false
      this.showAdd = ''
      this.description = ''
      this.fixed = null
      this.amount = 0
      this.showStep1 = !this.manageOrderRight
    },
    changePaymentType(type) {
      this.showAdd = true
      if (type === 'full') {
        this.amount = '€ ' + this.orderData.ex_vat
        this.fixed = 1
      } else {
        this.amount = ''
        this.fixed = 0
      }
      this.description = ''
      setTimeout(() => Helper.scrollTo('paymentCheckType', 'start'), 0)
    },
    addPayment() {
      if (!this.amount) {
        return false
      }
      let data = {
        order_id: this.orderData.order_id,
        full: this.fixed,
        amount: parseFloat(this.amount.replace(/[€ ,]/g, '')),
        desc: this.description,
        to_webshop: this.paymentType
      }

      if (data.amount > this.outstanding) {
        EventBus.$emit('showAlertModal', this.activeLanguage.amountIsHigher)
      } else if (!data.amount) {
        EventBus.$emit('showAlertModal', 'Amount field is empty')
      } else if (!data.desc.length) {
        EventBus.$emit('showAlertModal', 'Please fill Note field')
      } else {
        billinkAxios
            .post('/app/payment/apply', data)
            .then(({data}) => {
              if (data.status === 'success') {
                this.close()
                this.$emit('paymentIsAdded')
              }
            })
            .catch(err => {
              if (err.response.data.message) {
                EventBus.$emit('showAlertModal', err.response.data.message)
              }
              console.error(err)
            })
      }
    },
  },
  components: {
    InputPrice,
  },
}
</script>

<style scoped>
.form-check-label {
  cursor: pointer;
}

.custom-control-indicator {
  margin-top: -2px;
}
</style>