<template>
  <b-modal
    @ok="handleOk"
    @cancel="close"
    @close="close"
    @hidden="close"
    v-model="show"
    title="Track & Trace"
    header-class="card-header pause"
    class="pause-txt"
    ref="modal"
  >
    <form class="track-form" ref="form" @submit.stop.prevent="handleSubmit">
      <div class="form-group row mb-3 w-100">
        <label for="selectedCarrier">
          {{ activeLanguage.trackAndTraceModal.carrier }}
        </label>
        <select class="form-control" id="selectedCarrier"  v-model="selectedCarrier">
          <option v-for="carrier in carriers" :key="carrier.code" :value="carrier.code">
            {{ carrier.name }}
          </option>
        </select>
      </div>

      <div class="form-group row mb-2 w-100">
        <b-form-group
          label="Track & Trace"
          label-for="trackAndTrace"
          :invalid-feedback="activeLanguage.trackAndTraceModal.trackAndTraceErrorMessage"
          :state="trackAndTraceState"
          class="w-100"
        >
          <b-form-input
            type="text"
            v-model="trackAndTrace"
            :state="trackAndTraceState"
            id="trackAndTrace"
            class="form-control"
            :placeholder="activeLanguage.trackAndTraceModal.placeholder"
            required
          ></b-form-input>
        </b-form-group>
      </div>


      <div class="form-group row w-100">
        <label>
          {{ activeLanguage.trackAndTraceModal.uploadFiles }}
        </label>
        <b-form-file
          v-model="media"
          multiple
          :placeholder="activeLanguage.trackAndTraceModal.fileInputPlaceholder"
        ></b-form-file>

        <div
          v-if="trackerData[0] && trackerData[0].media"
          class="files"
        >
          <div
            v-for="file in trackerData[0].media"
            class="file"
          >
            <div class="file-name">
              {{ file.originalFileName }}
            </div>

            <button
              type="button"
              v-on:click="deleteFileFromTracker(file.id)"
              class="trash-button"
            >
              <i class="fa fa-trash" style="color: #6c757d"></i>
            </button>
          </div>
        </div>
      </div>
      <div v-if="trackAndTraceErrorMessage" class="error-message text-danger mt-2" style="font-size: 12px">
        {{ trackAndTraceErrorMessage }}
      </div>
    </form>
  </b-modal>
</template>

<script>
export default {
  props: {
    activeLanguage: {
      type: Object,
      required: true
    },
    carriers: {
      type: Array,
      required: true
    },
    trackerData: {
      type: Array,
      required: true
    },
    createTracker: {
      type: Function,
      required: true
    },
    updateTracker: {
      type: Function,
      required: true
    },
    deleteFileFromTracker: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      show: false,
      selectedCarrier: '',
      trackAndTrace: '',
      trackAndTraceState: null,
      trackAndTraceErrorMessage: '',
      media: [],
      allowedFileTypes: ['pdf', 'png', 'heic', 'jpg', 'jpeg', 'tiff', 'bmp'],
      maxFileSize: 25 * 1024 * 1024,
    }
  },
  computed: {
    isTrackAndTraceValid() {
      const pattern = /^[a-zA-Z0-9-]{5,}$/
      return pattern.test(this.trackAndTrace)
    }
  },
  methods: {
    open() {
      this.show = true
    },
    close() {
      this.show = false
      this.trackAndTraceErrorMessage = ''
      this.trackAndTraceState = null
      this.media = []
    },
    checkFormValidity() {
      const valid = this.isTrackAndTraceValid
      this.trackAndTraceState = valid
      return valid
    },
    async handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      await this.handleSubmit()
    },
    validateFile(file) {
      const name = file.name || file.contentUrl
      const fileType = name.split('.').pop().toLowerCase()
      if (!this.allowedFileTypes.includes(fileType)) {
        this.media = []
        return `${this.activeLanguage.trackAndTraceModal.fileTypeError}: ${fileType}`
      }
      if (file.size > this.maxFileSize) {
        this.media = []
        return `${this.activeLanguage.trackAndTraceModal.fileSizeError}: ${file.name}`
      }
      return null
    },
    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    async handleSubmit() {
      this.trackAndTraceErrorMessage = ''
      if (!this.checkFormValidity()) {
        return
      }

      for (const file of this.media) {
        const error = this.validateFile(file)
        if (error) {
          EventBus.$emit('showAlertModal', error)
          return
        }
      }

      const media = []
      for (const file of this.media) {
        const fileBase64 = await this.convertFileToBase64(file)
        media.push({
          fileName: file.name,
          fileBase64: fileBase64
        })
      }

      const newFileNames = media.map(item => item.fileName)
      if (this.trackerData.length > 0 && this.trackerData[0].media.some(file => newFileNames.includes(file.originalFileName))) {
        const existingFile = this.trackerData[0].media.find(file => newFileNames.includes(file.originalFileName));
        EventBus.$emit('showAlertModal', `${this.activeLanguage.trackAndTraceModal.fileExistsError1}: ${existingFile.originalFileName} ${this.activeLanguage.trackAndTraceModal.fileExistsError2}`);
        this.media = []
        return
      }

      try {
        if (this.trackerData.length > 0) {
          await this.updateTracker(this.selectedCarrier, this.trackAndTrace, media)
        } else {
          await this.createTracker(this.selectedCarrier, this.trackAndTrace, media)
        }
        this.$nextTick(() => {
          this.show = false
        })
      } catch (error) {
        if (error.response) {
          this.trackAndTraceErrorMessage = error.response.data.status === 422 ? this.activeLanguage.trackAndTraceModal.trackAndTraceErrorMessage : this.activeLanguage.trackAndTraceModal.trackAndTraceServerErrorMessage
        } else {
          this.trackAndTraceErrorMessage = this.activeLanguage.trackAndTraceModal.trackAndTraceServerErrorMessage || 'Network error'
        }
      }
    }
  },
  watch: {
    carriers: {
      immediate: true,
      handler(newCarriers) {
        if (newCarriers.length > 0 && !this.selectedCarrier) {
          this.selectedCarrier = this.trackerData[0] ? this.trackerData[0].carrier : newCarriers[0].name;
        }
      }
    },
    trackerData: {
      handler(newTrackerData) {
        if (newTrackerData.length > 0) {
          this.selectedCarrier = newTrackerData[0].carrier;
          this.trackAndTrace = newTrackerData[0].trackingCode;
        }
      }
    },
  },
}
</script>

<style lang="scss">
.track-form {
  .row {
    margin: 0;
  }
  .custom-file-label {
    font-size: 14px;
    font-weight: 400;
    padding-right: 86px;

    &::after {
      background: rgba(3, 36, 56, 0.95);
    }

    span {
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .trash-button {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 2px 4px;
    transition: transform .15s ease-in-out;
    border-radius: 4px;

    &:hover {
      background: #f8f9fa;
    }
  }
  .files {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
    margin-top: 8px;
    padding: 0 12px;

    .file {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .file-name {
        overflow: hidden;
        margin-right: 4px;
      }
    }
  }
}
</style>