<template>
    <b-modal
        @ok="close"
        @cancel="close"
        v-model="show"
        title="Payment link"
        header-class="card-header pause"
        class="pause-txt"
    >
        <div class="row">
            <div class="input-group">
                <input type="text" class="form-control copyLinkInput" :value="link">
                <div class="input-group-append" @click="doCopy">
                    <div class="input-group-text">
                    <i class="fas fa-copy"></i>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import $ from 'jquery'
export default {
    data() {
        return {
            show: false,
            link: ""
        }
    },
    methods: {
        open(link) { 
            this.show = true 
            this.link = link
        },
        close() { this.show = false },
        doCopy(e) {
            let selectEl = $(e.target).parents('.input-group').find('.copyLinkInput')[0];
            selectEl.select();
            document.execCommand("copy");
        }
    }
}
</script>