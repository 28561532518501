<template>
    <b-modal
        @ok="approveDeletion"
        @cancel="close"
        v-model="showModal"
        :title="itemType === 'credit' ? activeLanguage.creditHeader : activeLanguage.paymentHeader"
        header-class="card-header pause"
    >
        <div class="row">
            <div
                v-if="itemType === 'credit'"
                class="col-12"
                style="font-weight: 600;font-size: 18px;text-align: center;"
            >
                {{ activeLanguage.content }}
            </div>
            <div
                v-else
                class="col-12"
            >
                <div class="form-group" style="margin: 0">
                    <textarea
                        v-model="description"
                        style="resize: none"
                        class="form-control"
                        :placeholder="activeLanguage.description"
                    />
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
	export default {
		name: "DeleteCreditPaymentModal",
        props: {
			activeLanguage: {
				required: true,
                type: Object,
                default: function () {
                    return {}
                }
            }
        },
        data() {
			return {
				showModal: false,
				paymentData: {},
				itemType: '',
				description: ''
            }
        },
        methods: {
	        approveDeletion() {
	        	let data = this.paymentData
                if (this.itemType === 'payment') {
	                if (!this.description) {
                        return EventBus.$emit('showAlertModal', this.activeLanguage.description)
	                }
	                data.desc = this.description
                }

	        	billinkAxios
                    .post(`/app/${this.itemType}/delete`, data)
                    .then(({data}) => {
	                    this.close()
	                    if (!!data.message) {
		                    EventBus.$emit('showAlertModal', data.message)
	                    }
                        this.$emit('paymentCreditIsDeleted')
                    })
                    .catch(err => {
	                    console.error(err)
	                    if (err.response.data.message) {
                                EventBus.$emit('showAlertModal', err.response.data.message)
                            }
                    })

            },
            open(data, type) {
	        	this.showModal = true
                this.itemType = type
                this.paymentData = data
            },
            close() {
	            this.showModal = false
	            this.paymentData = {},
	            this.itemType = '',
                this.description = ''
            }
        }
	}
</script>

<style scoped>

</style>