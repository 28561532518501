<template>
  <div class="card">
    <h4 class="card-header">
      {{ customer.type }}
      <span>
        <i
          v-if="is_guarantee || !is_guarantee"
          @click="$emit('editClientDetails')"
          class="fa fa-pencil-square-o"
          aria-hidden="true"
          style="margin-left: 4px;cursor: pointer;"
        ></i>
      </span>
    </h4>

    <div class="card-body less-padding">
      <div class="content p-2">
        <div>
          <div v-if="customer.customer_info && customer.type === 'Bedrijf'">
            {{ customer.customer_info }}
          </div>

          <div v-if="customer.first_name || customer.last_name">
            {{ `${customer.first_name || ''} ${customer.last_name || ''}` }}
          </div>

          <div
            v-if="customer.street || customer.house_number || customer.extension || customer.postal_code || customer.city"
            @click="searchOrders({
              house: customer.house_number,
              zip :customer.postal_code
            })"
            class="search"
          >
            <div v-if="customer.street || customer.house_number || customer.extension">
              {{ `${customer.street || ''} ${customer.house_number || ''} ${customer.extension || ''}` }}
            </div>
            <div v-if="customer.postal_code || customer.city">
              {{ `${customer.postal_code || ''} ${customer.city || ''}` }}
            </div>
          </div>
        </div>

        <div v-if="customer.delivery_street || customer.delivery_house_number || customer.delivery_extension || customer.delivery_postal_code || customer.delivery_city">
          <div>
            {{ activeLanguage.deliveryAddress }}
          </div>
          <div>
            {{ `${customer.delivery_street || ''} ${customer.delivery_house_number || ''} ${customer.delivery_extension || ''}` }}
          </div>
          <div>
            {{ `${customer.delivery_postal_code || ''} ${customer.delivery_city || ''}` }}
          </div>
        </div>

        <div>
          <div
            v-if="customer.customer_email"
            @click="searchOrders({ email: customer.customer_email })"
            class="search"
          >
            {{ customer.customer_email }}
          </div>

          <div
            v-if="customer.phone"
            @click="searchOrders({ customerPhone: customer.phone })"
            class="search"
          >
            {{ customer.phone }}
          </div>

          <div
            v-if="(parseFloat(customer.coc_number) > 0) && customer.type === 'Bedrijf'"
            @click="searchOrders({ cocNumber: customer.coc_number })"
          >
            {{ customer.coc_number }}
          </div>

          <div v-if="customer.vat_number && customer.type === 'Bedrijf'">
            {{ customer.vat_number }}
          </div>

          <div v-if="(parseFloat(customer.ip) > 0)">
            {{ customer.ip }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import queryString from 'query-string'

export default {
  name: "ClientDetails",
  props: {
    customer: {
      type: Object,
      required: true
    },
    activeLanguage: {
      type: Object,
      required: true
    },
    manageOrderRight: {
      type: Boolean,
      default: false
    },
    is_guarantee: {
      type: Boolean
    }
  },
  data() {
    return {
      showDeliveryAddress: false,
    }
  },
  methods: {
    searchOrders(field) {
      this.$router.push(`/orders?${queryString.stringify(field)}`)
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  height: 358px !important;

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .search {
    cursor: pointer;

    &:hover {
      color: #4db5af;
    }
  }
}
</style>
