<template>
    <b-modal
        @ok="editOrder"
        @cancel="close"
        v-model="showModal"
        :title="'Order details edit'"
        header-class="card-header pause"
        class="pause-txt"
        size="lg"
    >
        <div class="row">
            <div class="col-12">
                <table class="table">
                    <thead>
                    <tr>
                        <th class="text-left" width="14%">{{ activeLanguage.code }}</th>
                        <th class="text-left table-des">{{ activeLanguage.desc }}</th>
                        <th width="10%">{{ activeLanguage.num }}</th>
                        <th class="amount-title text-left" width="15%">{{ activeLanguage.piece }}</th>
                        <th class="amount-title text-left" width="12%">{{ activeLanguage.vat }}</th>
                        <th class="amount-title text-left" width="15%">{{ activeLanguage.total }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in items">
                        <td class="text-left">
                            <input class="form-control" type="text" v-model="item.code">
                        </td>
                        <td class="text-left">
                            <input class="form-control" type="text" v-model="item.description">
                        </td>
                        <td>
                            <input class="form-control" type="text" v-model="item.order_quantity">
                        </td>
                        <td class="amount">
                            <InputPrice v-model="item.price_excluding_vat" />
                        </td>
                        <td>
                            <b-form-select
                                class="form-control"
                                id="BTW"
                                v-model="item.vat"
                                :options="vatOptions"
                            />
                        </td>
                        <td class="amount">
                          <InputPrice :value="countItemTotalPrice(item)" disabled />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </b-modal>
</template>

<script>
  import InputPrice from "../../common/reusable/InputPrice.vue";

	export default {
		name: "OrderItemsEditModal",
		props: {
			activeLanguage: {
				type: Object,
				default: function () {
					return {}
				}
			},
		},
		data() {
			return {
				showModal: false,
        items: [],
				vatOptions: [
					{ text: '0%', value: 1 },
					{ text: '6%', value: 1.06 },
					{ text: '7%', value: 1.07 },
					{ text: '9%', value: 1.09 },
					{ text: '12%', value: 1.12 },
					{ text: '19%', value: 1.19 },
					{ text: '20%', value: 1.20 },
					{ text: '21%', value: 1.21 }
				],
        totalPrice: ''
			}
        },
        methods: {
			countItemTotalPrice({ order_quantity, price_excluding_vat , vat}) {
				let priceInt = parseFloat(price_excluding_vat.replace(/[€ ,]/g, '')),
					quantityInt = parseFloat(order_quantity.replace(/,/g, '')),
					totalPrice = (priceInt * quantityInt * vat).toFixed(2)

				return '€ ' + Helper.formatAmount(totalPrice)
				// return (order_quantity * (price_excluding_vat.replace(/[€ ,]/g, ''))).toFixed(2)
            },
			close() {
				this.showModal = false
				this.items = []
            },
            open(items) {
                this.items = items.map(
                    ({ code, description, piece_price, total_items, id, vat = 1 }) => {
                        return {
	                        id,
                            code,
                            description,
	                        vat,
                            price_excluding_vat: piece_price,
                            order_quantity: total_items,
                        }
                    }
                )

				this.showModal = true
            },
	        editOrder() {
				this.items.forEach((item) => {
					item.price_excluding_vat = item.price_excluding_vat.replace(/[€ ,]/g, '')

                    let vatForDB = this.vatOptions.find((vatItem) => vatItem.value === item.vat)
                    item.vat = vatForDB.text.replace('%', '')
                })
                this.$emit('orderItemsAreUpdated', this.items, 'items')
                setTimeout(() => this.close(), 0)
	        },
        },
        components: {
          InputPrice
        }
	}
</script>

<style scoped lang="scss">
    .table {
        td {
            padding: .5rem !important;
        }
        tr:hover {
            background-color: inherit;
            cursor: default;
        }
    }
</style>