<template>
  <b-modal
      v-model="showModal"
      :title="title"
      :hide-footer="true"
      class="pause-text"
      header-class="card-header pause"
      size="lg"
  >
    <div class="row">
      <div class="col-md-6">
        <div class="form-group row">
          <label for="companyName" class="col-sm-6 col-form-label"> Company Name </label>
          <div class="col-sm-6">
            <input
                type="text"
                class="form-control"
                v-model="form.company_name"
                :name="`company_name`"
                id="companyName"
            >
          </div>
        </div>
        <div class="form-group row">
          <label for="customer_first_name" class="col-sm-6 col-form-label"> {{ activeLanguage.customerFirstName }} </label>
          <div class="col-sm-6">
            <input
                type="text"
                class="form-control"
                v-model="form.customer_first_name"
                :name="`customer_first_name`"
                id="customer_first_name"
            >
          </div>
        </div>
        <div class="form-group row">
          <label for="customer_last_name" class="col-sm-6 col-form-label"> {{ activeLanguage.customerLastName }} </label>
          <div class="col-sm-6">
            <input
                type="text"
                class="form-control"
                v-model="form.customer_last_name"
                :name="`customer_last_name`"
                id="customer_last_name"
            >
          </div>
        </div>
        
        <div class="form-group row">
          <label for="customer_initials" class="col-sm-6 col-form-label"> {{ activeLanguage.customer_initials }} </label>
          <div class="col-sm-6">
            <input
                type="text"
                class="form-control"
                v-model="form.customer_initials"
                :name="`customer_initials`"
                id="customer_initials"
            >
          </div>
        </div>

         <div class="form-group row">
          <label for="customer_birthdate" class="col-sm-6 col-form-label"> {{ activeLanguage.customer_birthdate }} </label>
          <div class="col-sm-6">
            <Datepicker
                v-model="form.customer_birthdate"
                :name="`customer_birthdate`"
                format="dd-MM-yyyy"
                :calendar-button="true"
                calendar-button-icon="fa fa-calendar"
                input-class="form-control actual_range"
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="customer_city" class="col-sm-6 col-form-label"> {{ activeLanguage.customer_city }} </label>
          <div class="col-sm-6">
            <input
                type="text"
                class="form-control"
                v-model="form.customer_city"
                :name="`customer_city`"
                id="customer_city"
            >
          </div>
        </div>
        
        <div class="form-group row">
          <label for="customer_street" class="col-sm-6 col-form-label"> {{ activeLanguage.customer_street }} </label>
          <div class="col-sm-6">
            <input
                type="text"
                class="form-control"
                v-model="form.customer_street"
                :name="`customer_street`"
                id="customer_street"
            >
          </div>
        </div>
        <div class="form-group row">
          <label for="customer_house_number" class="col-sm-6 col-form-label"> {{ activeLanguage.customer_house_number }} </label>
          <div class="col-sm-6">
            <input
                type="text"
                class="form-control"
                v-model="form.customer_house_number"
                :name="`customer_house_number`"
                id="customer_house_number"
            >
          </div>
        </div>
        <div class="form-group row">
          <label for="customer_house_extension" class="col-sm-6 col-form-label"> {{ activeLanguage.customer_house_extension }} </label>
          <div class="col-sm-6">
            <input
                type="text"
                class="form-control"
                v-model="form.customer_house_extension"
                :name="`customer_house_extension`"
                id="customer_house_extension"
            >
          </div>
        </div>
        <div class="form-group row">
          <label for="customer_postcode" class="col-sm-6 col-form-label"> {{ activeLanguage.customer_postcode }} </label>
          <div class="col-sm-6">
            <input
                type="text"
                class="form-control"
                v-model="form.customer_postcode"
                :name="`customer_postcode`"
                id="customer_postcode"
            >
          </div>
        </div>

        <div class="form-group row">
          <label for="customer_country_code" class="col-sm-6 col-form-label"> {{ activeLanguage.customer_country_code }} </label>
          <div class="col-sm-6">
            <input
                type="text"
                class="form-control"
                v-model="form.customer_country_code"
                :name="`customer_country_code`"
                id="customer_country_code"
            >
          </div>
        </div>
        <div class="form-group row">
          <label for="chamber_of_commerce_number" class="col-sm-6 col-form-label"> {{ activeLanguage.chamber_of_commerce_number }} </label>
          <div class="col-sm-6">
            <input
                type="text"
                class="form-control"
                v-model="form.chamber_of_commerce_number"
                :name="`chamber_of_commerce_number`"
                id="chamber_of_commerce_number"
            >
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group row">
          <label for="customer_delivery_street" class="col-sm-6 col-form-label"> {{ activeLanguage.customer_delivery_street }} </label>
          <div class="col-sm-6">
            <input
                type="text"
                class="form-control"
                v-model="form.customer_delivery_street"
                :name="`customer_delivery_street`"
                id="customer_delivery_street"
            >
          </div>
        </div>
        <div class="form-group row">
          <label for="customer_delivery_house_number" class="col-sm-6 col-form-label"> {{ activeLanguage.customer_delivery_house_number }} </label>
          <div class="col-sm-6">
            <input
                type="text"
                class="form-control"
                v-model="form.customer_delivery_house_number"
                :name="`customer_delivery_house_number`"
                id="customer_delivery_house_number"
            >
          </div>
        </div>
        <div class="form-group row">
          <label for="customer_delivery_house_extension" class="col-sm-6 col-form-label"> {{ activeLanguage.customer_delivery_house_extension }} </label>
          <div class="col-sm-6">
            <input
                type="text"
                class="form-control"
                v-model="form.customer_delivery_house_extension"
                :name="`customer_delivery_house_extension`"
                id="customer_delivery_house_extension"
            >
          </div>
        </div>
        <div class="form-group row">
          <label for="customer_delivery_postcode" class="col-sm-6 col-form-label"> {{ activeLanguage.customer_delivery_postcode }} </label>
          <div class="col-sm-6">
            <input
                type="text"
                class="form-control"
                v-model="form.customer_delivery_postcode"
                :name="`customer_delivery_postcode`"
                id="customer_delivery_postcode"
            >
          </div>
        </div>

        <div class="form-group row">
          <label for="delivery_address_first_name" class="col-sm-6 col-form-label"> {{ activeLanguage.delivery_address_first_name }} </label>
          <div class="col-sm-6">
            <input
                type="text"
                class="form-control"
                v-model="form.delivery_address_first_name"
                :name="`delivery_address_first_name`"
                id="delivery_address_first_name"
            >
          </div>
        </div>
        <div class="form-group row">
          <label for="delivery_address_last_name" class="col-sm-6 col-form-label"> {{ activeLanguage.delivery_address_last_name }} </label>
          <div class="col-sm-6">
            <input
                type="text"
                class="form-control"
                v-model="form.delivery_address_last_name"
                :name="`delivery_address_last_name`"
                id="delivery_address_last_name"
            >
          </div>
        </div>

        <div class="form-group row">
          <label for="customer_delivery_country_code" class="col-sm-6 col-form-label"> {{ activeLanguage.customer_delivery_country_code }} </label>
          <div class="col-sm-6">
            <input
                type="text"
                class="form-control"
                v-model="form.customer_delivery_country_code"
                :name="`customer_delivery_country_code`"
                id="customer_delivery_country_code"
            >
          </div>
        </div>
        <div class="form-group row">
          <label for="customer_delivery_city" class="col-sm-6 col-form-label"> {{ activeLanguage.customer_delivery_city }} </label>
          <div class="col-sm-6">
            <input
                type="text"
                class="form-control"
                v-model="form.customer_delivery_city"
                :name="`customer_delivery_city`"
                id="customer_delivery_city"
            >
          </div>
        </div>
        
        <div class="form-group row">
          <label for="delivery_address_company_name" class="col-sm-6 col-form-label"> {{ activeLanguage.delivery_address_company_name }} </label>
          <div class="col-sm-6">
            <input
                type="text"
                class="form-control"
                v-model="form.delivery_address_company_name"
                :name="`delivery_address_company_name`"
                id="delivery_address_company_name"
            >
          </div>
        </div>
      
      </div>
    </div>
        <div class="text-right">
              <div style="margin-left: auto; margin-top: 20px">
                <a
                    @click.prevent="downloadInvoice"
                    href="#"
                    class="btn high-z hidden-xs"
                >
                  Download new invoice
                </a>
              </div>
        </div>
  </b-modal>

</template>

<script>
import Datepicker from '../../common/reusable/datepicker/components/Datepicker'


export default {
  name: "fakeInvoice",
  props: {
    orderId: {
      type: [Number, null]
    },
    clientData: {
      type: Object,
      required: true
    },
    merchantData: {
      type: Object,
      required: true
    },
    customerData: {
      type: Object,
      required: true
    },
    activeLanguage: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showModal: false,
      title: "Change Invoice",
      form: {
        company_name: null,
        customer_first_name: null,
        customer_last_name: null,
        customer_initials: null,
        customer_birthdate: null,
        customer_city: null,
        customer_street: null,
        customer_house_number: null,
        customer_house_extension: null,
        customer_postcode: null,
        customer_country_code: null,
        chamber_of_commerce_number: null,
        customer_delivery_street: null,
        customer_delivery_house_number: null,
        customer_delivery_house_extension: null,
        customer_delivery_postcode: null,
        delivery_address_first_name: null,
        delivery_address_last_name: null,
        customer_delivery_country_code: null,
        customer_delivery_city: null,
        delivery_address_company_name: null
      }
    }
  },
  mounted() {
    this.setInvoiceData()
  },
  watch: {
    customerData: function () {
      this.setInvoiceData()
    },
    clientData: function () {
      this.setInvoiceData()
    }
  },
  methods: {
    setInvoiceData() {
      this.form = {
        ...this.form,
        ...(this.clientData && this.clientData.customer_first_name) && {customer_first_name: this.clientData.customer_first_name},
        ...(this.clientData && this.clientData.customer_last_name) && {customer_last_name: this.clientData.customer_last_name},
        ...(this.customerData.birthday) && {customer_birthdate: this.customerData.birthday},
        ...(this.customerData.city) && {customer_city: this.customerData.city},
        ...(this.customerData.coc_number) && {chamber_of_commerce_number: this.customerData.coc_number},
        ...(this.customerData.customer_info) && {customer_initials: this.customerData.customer_info},
        ...(this.customerData.country_code) && {customer_country_code: this.customerData.country_code},
        ...(this.customerData.delivery_country) && {customer_delivery_country_code: this.customerData.delivery_country},
        ...(this.customerData.delivery_city) && {customer_delivery_city: this.customerData.delivery_city},
        ...(this.customerData.delivery_company) && {delivery_address_company_name: this.customerData.delivery_company},
        ...(this.customerData.delivery_extension) && {customer_delivery_house_extension: this.customerData.delivery_extension},
        ...(this.customerData.delivery_house_number) && {customer_delivery_house_number: this.customerData.delivery_house_number},
        ...(this.customerData.delivery_postal_code) && {customer_delivery_postcode: this.customerData.delivery_postal_code},
        ...(this.customerData.delivery_street) && {customer_delivery_street: this.customerData.delivery_street},
        ...(this.customerData.extension) && {customer_house_extension: this.customerData.extension},
        ...(this.customerData.house_number) && {customer_house_number: this.customerData.house_number},
        ...(this.customerData.postal_code) && {customer_postcode: this.customerData.postal_code},
        ...(this.customerData.street) && {customer_street: this.customerData.street},
        ...(this.clientData && this.clientData.company_name) && {company_name: this.clientData.company_name}
      }
    },
    open(){
      this.showModal = true;
    },
    close(){
      this.showModal = false;
    },
    downloadInvoice() {
        billinkAxios
            .post(`custom_post_invoice/order/${this.orderId}`, this.form,)
            .then((res) => {
              let link = document.createElement('a');

              link.href = res.data.url
              link.setAttribute('download', `download.pdf`);
              document.body.appendChild(link);
              link.click();
              link.remove();
            })
            .catch(err => {
              console.error(err)
            })
    }
  },
  components: {
    Datepicker
  }
}
</script>

<style lang="scss">
.modal-lg {
  max-width: 1000px !important;
}
</style>