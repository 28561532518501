<template>
        <div class="card z-i">
            <h4 class="card-header">{{ activeLanguage.title }}</h4>
            <div class="card-body">
                <div class="activity collapse show" id="activity-field" v-show="showNewActivity">
                    <textarea
                        class="form-control high-z"
                        id="exampleFormControlTextarea1"
                        rows="4"
                        v-model="newActivityText"
                    ></textarea>
                    <br />
                    <div class="activity-close-overlay"></div>
                </div>
                <template v-if="!showNewActivity">
                    <a
                        @click.prevent="addActivity"
                        href="#"
                        class="btn high-z hidden-xs"
                    >
                        {{ activeLanguage.addItemBtn }}
                    </a>
                </template>
                <template v-if="showNewActivity">
                    <a
                        @click.prevent="saveActivity"
                        href="#"
                        class="btn high-z hidden-xs activeField"
                    >
                        {{ activeLanguage.saveItemBtn }}
                    </a>
                    <a
                        @click.prevent="cancelActivity"
                        href="#"
                        class="btn high-z hidden-xs activeField"
                    >
                        {{ activeLanguage.cancelItemBtn }}
                    </a>
                </template>
                <ul class="activity-list">
                    <li
                        class="activ clearfix"
                        style="display: list-item"
                        v-for="log in visibleLogs"
                    >
                        <p>
                            <strong>{{ log.date }}</strong>
                        </p>
                        <p>{{log.description}}</p>
                        <a
                            v-if="!!log.email_status.length"
                            href="#"
                            class="float-right hidden-xs ml-2"
                            style="font-size: 20px;vertical-align: top;"
                            @click.prevent="showEmailStatus(log)"
                        >
                            <i class="fa fa-caret-down" aria-hidden="true" style="vertical-align: inherit;"></i>
                        </a>
                        <a
                            target="_blank"
                            v-if="log.email_url"
                            href="#"
                            class="emaillink float-right hidden-xs"
                            :class="!!log.email_status.length ? 'ml-2' : 'ml-3'"
                            @click.prevent="getFile(log.email_url, 'email')"
                        >
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                            E-mail
                        </a>
                        <a
                            target="_blank"
                            v-if="log.file_url"
                            href="#"
                            class="filelink  float-right  hidden-xs"
                            @click.prevent="getFile(log.file_url, 'pdf')"
                        >
                            <i class="fa fa-file-text" aria-hidden="true"></i>
                            File
                        </a>
                        <a
                            href="#"
                            v-if="log.email_url && manageOrderRight"
                            class="emaillink float-left"
                            @click.prevent="resendEmail(log)"
                        >
                            Resend
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                        </a>
                        <template v-if="log.shown && !isClient">
                            <table class="email-table">
                                <tr v-for="email in log.email_status">
                                    <td>{{ email.date }}</td>
                                    <td>{{ email.status }}</td>
                                </tr>
                            </table>
                        </template>
                    </li>
                </ul>
                <a
                    @click.prevent="loadMoreActivities"
                    v-if="showLoadActivitiesBtn"
                    href="#"
                    class="expanded btn btn-block"
                    id="loadMore"
                >
                    {{ activeLanguage.loadMoreBtn }}
                </a>
            </div>
        </div>
</template>

<script>
	export default {
		name: "activity-history",
        props: {
			logs: {
				type: Array,
                required: true
            },
            activeLanguage: {
				type: Object,
                required:true
            },
	        manageOrderRight: {
				type: Boolean,
                required: true
            },
            isClient: {
                type: Boolean
            }
        },
        data() {
			return {
				showNewActivity: false,
                maxLogs: 5,
				newActivityText: '',
				showLoadActivitiesBtn: false
            }
        },
        computed: {
	        visibleLogs() { return this.logs ? this.logs.slice(0, this.maxLogs) : [] }
        },
        watch: {
			logs: function(val) { this.showLoadActivitiesBtn = this.maxLogs + 1 <= this.logs.length }
        },
        mounted() {
            this.showLoadActivitiesBtn = this.maxLogs + 1 <= this.logs.length 
        },
        methods: {
	        addActivity() {
                this.showNewActivity = true
                this.$emit('activeActivity')
            },
	        cancelActivity() {
		        this.showNewActivity = false
		        this.newActivityText = ''
		        this.$emit('cancelActivity')
            },
	        saveActivity() {
	        	this.$emit('saveActivity', this.newActivityText)
            },
	        successfullyAdded(message) {
	            this.logs.unshift(message)
	            this.showNewActivity = false
	            this.newActivityText = ''
	            this.showLoadActivitiesBtn = this.maxLogs + 1 <= this.logs.length
            },
	        loadMoreActivities() {
		        this.maxLogs = this.maxLogs + 5 < this.logs.length ? this.maxLogs + 5 : this.logs.length

	        	if (this.maxLogs >= this.logs.length) this.showLoadActivitiesBtn = false
            },
	        showEmailStatus(log) {
	        	log.shown = !log.shown
                this.$forceUpdate()
            },
	        getFile(url, type) {
		        this.$emit('getFile', { url, type })
            },
	        resendEmail({email_id}) {
		        this.$emit('resendEmail', email_id)
            }
        }
	}
</script>

<style scoped lang="scss">
    .z-i {
        z-index: 100;
    }
    .expanded.btn.btn-block {
        white-space: unset;
    }
    .btn.high-z {
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
    }
    .btn.activeField {
        width: 45%;
        &:nth-of-type(1) {
            margin-right: 10%
        }
    }
    textarea {
        resize: none;
    }
    @media (max-width: 1200px) {
        .expanded.btn.btn-block {
            font-size: 10px;
        }
    }
    .car {margin-bottom: 0;}
</style>