<template>
    <b-modal
        hide-footer
        v-model="showModal"
        :title="activeLanguage.title"
        header-class="card-header pause"
    >
        <div class="row mb-1" :class="!!whatToShow ? 'mb-4' : ''">
            <div class="col-12 btnPlace">
                <a href="" @click.prevent="show('add')" class="btn">{{ activeLanguage.add }}</a>
                <a href="" v-if="!!penalties.length" @click.prevent="show('remove')" class="btn">{{ activeLanguage.remove }}</a>
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-if="whatToShow === 'add'">
                <div class="form-group row">
                    <div class="col-sm-8">
                        <InputPrice
                          v-model="amount"
                          :placeholder="activeLanguage.amount"
                        />
                    </div>
                    <div class="col-sm-4">
                        <button
                            @click="changePenalty('add')"
                            :class="!(amount === '€ 0' || !amount) ? '' : 'btn-secondary'"
                            class="text-center btn"
                            type="button"
                        >Submit</button>
                    </div>
                </div>
            </div>
            <div class="col-12" v-if="whatToShow === 'remove'">
                <table class="table">
                    <thead>
                    <tr>
                        <th class="text-left">{{ activeLanguage.title}}</th>
                        <th class="amount-title text-left"></th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="penalty in penalties"
                            @click="changePenalty('remove', penalty.id)"
                            style="cursor: pointer;"
                        >
                            <td class="text-left border-top border-bottom">
                                <strong>{{ penalty.name }}</strong>
                            </td>
                            <td class="text-right border-top border-bottom amount">
                                <strong><span>€</span> {{ penalty.amount }}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </b-modal>
</template>

<script>
  import InputPrice from "../../common/reusable/InputPrice.vue";

	export default {
		name: "PenaltyModal",
		props: {
			activeLanguage: {
				required: true,
				type: Object,
				default: function () {
					return {}
				}
			},
			penalties: {
				required: true,
				type: Array
			},
			order_id: {
				required: true,
            }
		},
        data() {
			return {
				showModal: false,
				whatToShow: '',
				amount: '€ 0',
            }
        },
        watch: {
	        showModal: function (val) {
                if (!val) {
                	this.close()
                }
	        }
        },
        methods: {
	        show(show) {
		        this.amount = '€ 0'
	        	this.whatToShow = show
            },
	        open() {
		        this.showModal = true
	        },
	        close() {
		        this.showModal = false
		        this.whatToShow = ''
                this.amount = '€ 0'
	        },
	        changePenalty(type, id = null) {
	        	let data = {
			        order: this.order_id
                }

                if (type === 'add') {
	        		data = {
				        ...data,
				        penalty: "new",
				        amount: parseFloat(this.amount.replace(/[€ ,]/g, ''))
			        }
                } else if (type === 'remove') {
	        		data = {
                        ...data,
				        amount: 0,
				        penalty: id
			        }
                }

	        	billinkAxios
                    .post('app/penalty/change', data)
                    .then(({data}) => {
	                    EventBus.$emit('showAlertModal', data.message)
                        this.$emit('updateOrder')
                    })
                    .catch(err => {
	                    console.error(err)
	                    EventBus.$emit('showAlertModal', 'Something went wrong')
                    })
                    .then(() => this.close())
            }
        },
        components: {
          InputPrice,
        }
	}
</script>

<style scoped>
    .btnPlace {
        display: flex;
        justify-content: space-around;
    }
</style>