<template>
    <b-modal
        @ok="validatePauseForm"
        @cancel="close"
        v-model="showPauseModal"
        :title="activeLanguage.title"
        header-class="card-header pause"
        class="pause-txt"
    >
        <div class="row">
            <div class="col-12" style="margin-top: 5px;">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <Datepicker
                                :calendar-button="true"
                                calendar-button-icon="fa fa-calendar"
                                input-class="form-control actual_range"
                                :placeholder="activeLanguage.until"
                                :highlighted="highlighted"
                                format="dd-MM-yyyy"
                                v-model="form.date"
                                :disabledDates="manageOrderRight ? {} : datesDisabled"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group" style="margin-top: 15px;">
                    <textarea
                        v-model="form.reason"
                        style="resize: none"
                        class="form-control"
                        :placeholder="activeLanguage.txt"
                    />
                </div>
            </div>
            <div class="col-12" style="margin-top: 15px;">
                <div class="row">
                    <h6 class="col-12">{{ activeLanguage.contact }}</h6>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="custom-control custom-checkbox">
                            <input v-model="contactType" type="radio" name="pauseMethod" :value="activeLanguage.email" class="custom-control-input">
                            <span class="custom-control-indicator"></span>
                            <span class="custom-control-txt">{{ activeLanguage.email }}</span>
                        </label>
                    </div>
                    <div class="col-3">
                        <label class="custom-control custom-checkbox">
                            <input v-model="contactType" type="radio" name="pauseMethod" :value="activeLanguage.phone" class="custom-control-input">
                            <span class="custom-control-indicator"></span>
                            <span class="custom-control-txt">{{ activeLanguage.phone }}</span>
                        </label>
                    </div>
                    <div class="col-4">
                        <label class="custom-control custom-checkbox">
                            <input v-model="contactType" type="radio" name="pauseMethod" :value="activeLanguage.facebook" class="custom-control-input">
                            <span class="custom-control-indicator"></span>
                            <span class="custom-control-txt">{{ activeLanguage.facebook }}</span>
                        </label>
                    </div>
                    <div class="col-3">
                        <label class="custom-control custom-checkbox">
                            <input v-model="contactType" type="radio" name="pauseMethod" :value="activeLanguage.chat" class="custom-control-input">
                            <span class="custom-control-indicator"></span>
                            <span class="custom-control-txt">{{ activeLanguage.chat }}</span>
                        </label>
                    </div>
                    <div class="col-3">
                        <label class="custom-control custom-checkbox">
                            <input v-model="contactType" v-validate="'required'" type="radio" name="pauseMethod" :value="activeLanguage.other" class="custom-control-input">
                            <span class="custom-control-indicator"></span>
                            <span class="custom-control-txt">{{ activeLanguage.other }}</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    import moment from 'moment'
	import Datepicker from 'vuejs-datepicker'

	export default {
		name: "PauseOrderModal",
        props: ['activeLanguage', 'order_id', 'username', 'manageOrderRight'],
        data() {
            return {
	            showPauseModal: false,
	            datesDisabled: {
		            ranges: [
		            	{
                            from: new Date(0),
                            to: new Date(Date.now())
                        },
                        {
                            from: new Date(Date.now() + 1296000000),
                            to: new Date(2100, 1, 1)
                        }
                    ],
	            },
	            contactType: '',
                form: {
	                date: null,
	                manual: false,
                    reason: '',
                },
              highlighted: {
                dates: [
                  new Date()
                ]
              }
            }
        },
        methods: {
			open() {
                this.showPauseModal = true
            },
            close() {
				this.contactType = ''
	            this.form = {}
	            this.showPauseModal = false
                // this.showErr()
            },
	        validatePauseForm(e) {
				e.preventDefault()
		        this.$validator
			        .validate()
                    .then(res => {
                    	if (res) {
		                    this.pauseOrder()
                        } else {
		                    // this.showErr('Kies contactmoment!')
                            EventBus.$emit('showAlertModal', 'Kies contactmoment!')
                        }
                    })
            },
            pauseOrder() {
				let data = {
					...this.form,
                    order_id: this.order_id
				},  pauseData = !!data.date ? moment(data.date).format('D-MM-YYYY') : moment(new Date()).add(1, 'd').format('D-MM-YYYY'),
                    reason = !!data.reason ? ` ${data.reason}` : '',
                    pauseTxt = this.activeLanguage.lang === 'en' ? 'Pause till' : 'Pauze tot'

				data.contact = `${pauseTxt} ${pauseData}:${reason} (via ${this.contactType} / ${this.username})`

	            billinkAxios
		            .post('app/order/set-on-hold', data)
		            .then(({ data }) => {
			            this.$emit('pauseOrder')
			            this.close()
                    })
		            .catch(err => {
			            console.error(err)
			            if (err.response.status === 400) {
                            if (err.response.data.message === "Client has reached the pause limit for this order") {
                                EventBus.$emit('showAlertModal', this.activeLanguage.limitErr)
                                this.close()
                            } else {
                                if (err.response.data.message) {
                                    EventBus.$emit('showAlertModal', err.response.data.message)
                                } 
                            }
                            
			            }
		            })
            },
            showErr(mes = '') {
	            document
                    .querySelector('.pause-txt .modal footer.modal-footer')
                    .dataset.txt = mes
            }
        },
		components: {
			Datepicker
		},
	}
</script>

<style scoped>
    .custom-control {
        cursor: pointer;
    }
    .custom-control-txt {
        vertical-align: middle;
    }
</style>

<style>
.modal-header.card-header.pause .modal-title{
    color: #ffffff !important;
}
 .pause-txt .modal footer.modal-footer:before {
     content: attr(data-txt);
     display: inline-block;
     margin-right: 20px;
     font-size: 16px;
     color: red;
 }
</style>