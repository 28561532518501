<template>
    <b-modal
            @ok="editPenalty('edit')"
            @cancel="close"
            v-model="showModal"
            :title="activeLanguage.title"
            header-class="card-header pause"
    >
        <div class="row">
            <h5 class="col-12 mb-3">{{ activeLanguage.content }}</h5>
        </div>
        <div class="form-group row">
            <div class="col-8">
                <InputPrice
                  v-model="amount"
                  :placeholder="activeLanguage.amount"
                />
            </div>
            <div class="col-4 text-right">
                <a href="#" @click.prevent="editPenalty('remove')" class="btn">Delete</a>
            </div>
        </div>
    </b-modal>
</template>

<script>
  import InputPrice from "../../common/reusable/InputPrice.vue";

	export default {
		name: "PenaltyModalEdit",
		props: {
			activeLanguage: {
				required: true,
				type: Object,
				default: function () {
					return {}
				}
			},
			order_id: {
				required: true,
			}
		},
        data() {
			return {
				showModal: false,
                amount: '0',
				penalty_id: null,
            }
        },
        methods: {
			open(penalty) {
				this.amount = `€ ${penalty.amount}`
				this.penalty_id = penalty.id
				this.showModal = true
            },
            close() {
				this.showModal = false
	            this.amount = `€ 0`
	            this.penalty_id = null
            },
	        editPenalty(type) {
		        let data = {
			        order: this.order_id,
			        penalty: this.penalty_id,
			        amount: type === 'remove' ? 0 : parseFloat(this.amount.replace(/[€ ,]/g, ''))
		        }

		        billinkAxios
			        .post('app/penalty/change', data)
			        .then(({data}) => {
				        EventBus.$emit('showAlertModal', data.message)
				        this.$emit('updateOrder')
			        })
			        .catch(err => {
				        console.error(err)
				        EventBus.$emit('showAlertModal', 'Something went wrong')
			        })
			        .then(() => this.close())
            }
        },
		components: {
      InputPrice,
		}
	}
</script>

<style scoped>

</style>