<template>
  <div class="card">
    <h4 class="card-header">{{ activeLanguage.title }}</h4>
    <div class="card-body less-padding">
      <table class="table">
        <thead>
        <tr>
          <th class="text-left" style="width: 96px;">{{ activeLanguage.date }}</th>
          <th class="text-left hidden-xs">{{ activeLanguage.type }}</th>
          <th class="hidden-xs">{{ activeLanguage.by }}</th>
          <th class="text-left">{{ activeLanguage.desc }}</th>
          <th>{{ activeLanguage.amount }}</th>
          <th v-if="alloweeToDelete"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="credit in credits">
          <td class="text-left">{{ credit.date }}</td>
          <td class="text-left hidden-xs">{{ credit.type }}</td>
          <td class="hidden-xs">{{ credit.by }}</td>
          <td class="text-left">{{ credit.description }}</td>
          <td class="amount"><span>€</span> {{ formattedAmount(credit.amount) }}</td>
          <td
              class="deleteCredit"
              @click="deleteCredit(credit)"
              v-if="alloweeToDelete"
          >
            <i class="fa fa-trash" aria-hidden="true"></i>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreditHistory",
  props: {
    credits: {
      type: Array,
      required: true,
      default: function () {
        return []
      }
    },
    activeLanguage: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    alloweeToDelete: {
      type: Boolean,
      required: true,
      default: function () {
        return false
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    deleteCredit(credit) {
      this.$emit('deleteCredit', 'credit', credit)
    }
  },
  computed: {
    formattedAmount() {
      return (amount) => Helper.formatAmount(amount);
    },
  },
}
</script>

<style>
.deleteCredit {
  color: #bcbdc0;
  cursor: pointer;
  text-align: center;
  font-size: 20px;
  padding-top: 3px !important;
}

.deleteCredit:hover {
  color: red;
}

.deleteCredit i {
  vertical-align: middle;
}
</style>