<template>
  <div class="card">
    <h4 class="card-header">
      Order details
      <span>
        <i
          v-if="!!manageOrderRight || !is_guarantee"
          @click="$emit('editOrderInfo')"
          class="fa fa-pencil-square-o"
          aria-hidden="true"
          style="margin-left: 4px; cursor: pointer"
        ></i>
      </span>
    </h4>
    <div class="card-body less-padding">
      <table class="table">
        <thead>
          <tr>
            <th class="text-left">{{ activeLanguage.code }}</th>
            <th class="text-left table-des">
              {{ activeLanguage.desc }}
            </th>
            <th>{{ activeLanguage.num }}</th>
            <th class="amount-title text-left">{{ activeLanguage.piece }}</th>
            <th class="amount-title text-left">{{ activeLanguage.total }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items">
            <td class="text-left">{{ item.code }}</td>
            <td class="text-left">
              <div class="overflow">{{ item.description }}</div>
            </td>
            <td>{{ item.total_items }}</td>
            <td class="amount">
              <span>€</span>
              {{ formattedAmount(item.piece_price_inc_vat) }}
            </td>
            <td class="amount">
              <span>€</span>
              {{ formattedAmount(item.total_price_inc_vat) }}
            </td>
          </tr>
        </tbody>
        <tfoot style="background-color: transparent" class="border-top">
          <tr>
            <td></td>
            <td></td>
            <td class="text-left" colspan="2">
              {{ activeLanguage.subtotalIncVat }}
            </td>
            <td class="text-right amount">
              € {{ formattedAmount(order_info.total_price_inc_vat) }}
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td class="text-left" colspan="2">
              {{ activeLanguage.vat }}
            </td>
            <td class="text-right amount">
              € {{ formattedAmount(order_info.vat) }}
            </td>
          </tr>
          <tr v-for="penalty in penalties">
            <td></td>
            <td></td>
            <td class="text-left" colspan="2">
              <a
                @click.prevent="manageOrderRight && editPenalties(penalty)"
                href=""
                class="deleteLogo hidden-xs"
              >
                <div style="display: inline-flex">
                  <p style="margin: 0 15px 0 0">Penalty fee</p>
                  <i
                    v-if="manageOrderRight"
                    class="fa fa-edit"
                    aria-hidden="true"
                  ></i>
                </div>
              </a>
            </td>
            <td
              class="text-right amount"
              :style="penalty.amount > 0 ? { color: 'red' } : ''"
            >
              € {{ formattedAmount(penalty.amount) }}
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td class="text-left" colspan="2">
              {{ activeLanguage.creditsPayments }}
            </td>
            <td class="text-right amount">
              €
              {{
                order_info.credit_payments_total === "0.00"
                  ? formattedAmount(order_info.credit_payments_total)
                  : `-${formattedAmount(order_info.credit_payments_total)}`
              }}
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td class="text-left" colspan="2">
              <strong>{{ activeLanguage.totalOutstanding }}</strong>
            </td>
            <td class="text-right amount">
              <strong>€ {{ formattedAmount(order_info.outstanding) }}</strong>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderItems",
  props: {
    items: {
      type: Array,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    activeLanguage: {
      type: Object,
      required: true,
    },
    order_info: {
      type: Object,
      required: true,
    },
    penalties: {
      type: Array,
      required: true,
    },
    manageOrderRight: {
      type: Boolean,
      default: false,
    },
    is_guarantee: {
      type: Boolean,
    },
    orderType: {
      type: String,
    },
    editPenalty: {
      type: Function,
    },
  },
  mounted() {},
  data() {
    return {
      penaltySum: 0.0,
    };
  },
  computed: {
    formattedAmount() {
      return (amount) => Helper.formatAmount(amount);
    },
  },
  watch: {
    penalties: {
      handler: function (val) {
        val.map((penalty) => {
          this.penaltySum += parseFloat(penalty.amount);
        });
      },
      deep: true,
    },
  },
  methods: {
    editPenalties(penalty) {
      this.$emit("editPenalty", penalty);
    },
  },
};
</script>

<style scoped>
.fa-edit {
  color: gray;
}
.table thead {
  background: none;
}
td.amount {
  width: 94px;
}
th.amount-title {
  width: 105px;
}

.overflow {
  max-width: 420px;
}
.penIco span {
  display: inline-block;
  line-height: 22px;
}
.penIco i {
  display: inline-block;
  line-height: 22px;
  margin-left: 6px;
  font-size: 14px;
  cursor: pointer;
  color: rgb(128, 128, 128);
}
.penIco i:before {
  vertical-align: bottom;
}
.penIco i:hover {
  color: #f48d21;
}

@media (max-width: 1250px) {
  .overflow {
    max-width: 20vw;
  }
}

@media (max-width: 920px) {
  .overflow {
    max-width: 130px;
  }
}

@media (max-width: 420px) {
  .overflow {
    max-width: 79px;
  }

  .card .table th {
    padding-left: 5px;
    padding-right: 5px;
  }

  .table td {
    padding-left: 5px;
    padding-right: 5px;
  }
}
</style>
