import { render, staticRenderFns } from "./ClientDetails.vue?vue&type=template&id=02db4378&scoped=true&"
import script from "./ClientDetails.vue?vue&type=script&lang=js&"
export * from "./ClientDetails.vue?vue&type=script&lang=js&"
import style0 from "./ClientDetails.vue?vue&type=style&index=0&id=02db4378&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02db4378",
  null
  
)

export default component.exports