<template>
    <b-modal
        v-model="showFileModal"
        :hide-footer="true"
        :hide-header="true"
        size="lg"
    >
        <iframe v-if="showIframe" ref="iframe" frameborder="0"></iframe>
    </b-modal>
</template>

<script>
	export default {
		name: "FileShowModal",
        data() {
			return {
				showFileModal: false,
				showIframe: true
            }
        },
        methods: {
	        open({url, type}) {
                this.getFile(url, type)
	        },
	        close() {
		        this.showFileModal = false
	        },
	        getFile(url, type) {
		        this.showIframe = false
		        if (type === 'pdf') {
			        billinkAxios
				        .get(url.replace('/api/', ''), { responseType: 'blob' })
				        .then((res) => {
					        this.showIframe = true
					        setTimeout(() => {
						        this.showFileModal = true
                                let iframe = this.$refs['iframe']
                                iframe.src =  window.URL.createObjectURL(res.data)
					        }, 0)
				        })
				        .then(() => this.showFileModal = true)
				        .catch(err => console.error(err))
		        } else {
			        billinkAxios
				        .get(url.replace('/api/', ''))
				        .then(({data}) => {
					        this.showIframe = true
                            setTimeout(() => {
	                            let iframe = this.$refs['iframe'].contentWindow.document
	                            iframe.open();
	                            iframe.write(data.email);
	                            iframe.close();
                            }, 0)
				        })
                        .then(() => this.showFileModal = true)
				        .catch(err => console.error(err))
		        }
	        }
        }
	}
</script>

<style scoped>
    iframe {
        height: 800px;
        width: 100%;
    }
</style>