<template>
    <div class="card">
      <h4 class="card-header">{{ activeLanguage.title }}</h4>
        <div class="card-body less-padding">
          <div class="p-2">
            <div v-if="date">
              <strong>{{ activeLanguage.orderDate }}:</strong> {{ date }}
            </div>

            <div v-if="!!employeeOrderData.pauseTill">
              <strong>{{ activeLanguage.pause }}:</strong> {{ employeeOrderData.pauseTill }}
            </div>

            <div v-if="!!employeeOrderData.paymentBefore">
              <strong>{{ activeLanguage.payment }}:</strong> {{ employeeOrderData.paymentBefore }}
            </div>

            <div v-if="trackerData.length > 0">
              <strong>Track & Trace:</strong> {{ trackerData[0].trackingCode }}
            </div>

            <div v-if="!!employeeOrderData.provider">
              <strong>{{ activeLanguage.provider }}:</strong> {{ employeeOrderData.provider }}
            </div>

            <div>
              <strong>{{ activeLanguage2.guarantee }}</strong> {{ order_info.is_guarantee ? activeLanguage2.yes : activeLanguage2.no}}
            </div>

            <div v-if="invoiceDate">
              <strong>{{ activeLanguage.payoutAfter }}:</strong> {{ invoiceDate }}
            </div>

            <div v-if="!!employeeOrderData.invDoc">
              <strong>{{ activeLanguage.inv }}: </strong>
              <a
                style="margin-right: 10px;"
                target="_blank"
                v-if="employeeOrderData.invDoc.email_url"
                href="#"
                class="emaillink hidden-xs"
                @click.prevent="getFile(employeeOrderData.invDoc.email_url, 'email')"
              >
                <i class="fa fa-envelope" aria-hidden="true"></i>
                E-mail
              </a>
              <a
                target="_blank"
                v-if="employeeOrderData.invDoc.file_url"
                href="#"
                class="filelink hidden-xs"
                @click.prevent="getFile(employeeOrderData.invDoc.file_url, 'pdf')"
              >
                <i class="fa fa-file-text" aria-hidden="true"></i>
                File
              </a>
            </div>

            <div v-if="!!order_info.variable1">
              <strong>{{ activeLanguage2.header1 }}</strong> {{ order_info.variable1 }}
            </div>

            <div v-if="!!order_info.variable2">
              <strong>{{ activeLanguage2.header2 }}</strong> {{ order_info.variable2 }}
            </div>
          </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OrderInfoEmployee",
        props: {
          trackerData: {
            type: Array,
            default: []
          },
          employeeOrderData: {
              type: Object
          },
          activeLanguage: {
              type: Object,
              required: true,
            default: function() { return {} }
          },
	        activeLanguage2: {
		        type: Object,
		        required: true,
		        default: function() { return {} }
	        },
	        date: {
		        type: String,
		        required: true
	        },
          order_info: {
            type: Object,
            required: true
          },
          invoiceDate: {
              type: [ Object, String ]
          },
        },
        data() {
            return {

            }
        },
        methods: {
	        getFile(url, type) {
		        this.$emit('getFile', { url, type })
	        }
        }
    }
</script>

<style scoped lang="scss">
    .card{
        height: 358px !important;
    }
</style>