<template>
    <b-modal
        @ok="ok"
        @cancel="close"
        v-model="show"
        title="Warning!"
        header-class="card-header pause"
        class="pause-txt"
    >
        <div class="row">
            <div class="container" style="text-align: center;">
                <p>{{ message }}</p>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: "PauseWarningModal",
    props: {
        message: {
            type: String
        }
    },
    data() {
        return {
            show: false
        }
    },
    methods: {
        open() { this.show = true; },
        close() { this.show = false; },
        ok() {
            this.$emit('openPauseModal')
            this.close()
        }
    }
}
</script>